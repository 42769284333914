import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'
import DialogActions from '../../components/DialogActions'
import * as catalogService from '../../services/catalog'

interface IFormFields {
  references: string
}

type IProps = {
  onClose: () => void
}

const ExtendedDialog: FC<IProps> = props => {
  const dispatch = useDispatch()

  const location = useLocation()
  const history = useHistory()

  const { formatMessage: f } = useIntl()

  const schema = yup.object().shape<IFormFields>({
    references: yup.string().required()
  })

  return (
    <Formik<IFormFields>
      initialValues={{ references: '' }}
      validationSchema={schema}
      onSubmit={values => {
        location.pathname !== '/catalog' && history.push('/catalog')
        dispatch(catalogService.actions.searchWithReferencesRequest(values.references))
        props.onClose()
      }}
    >
      {form => (
        <Form>
          <DialogContent>
            <TextField
              error={form.touched.references && !!form.errors.references}
              autoFocus
              label={f({ id: 'references' })}
              placeholder={f({ id: 'past_references_here_ellipsis' })}
              fullWidth
              multiline
              onBlur={_ => form.setFieldTouched('references')}
              onChange={event => form.setFieldValue('references', event.target.value)}
              rows={5}
              rowsMax={10}
              value={form.values.references}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions onClose={props.onClose} cancelTerm={f({ id: 'close' })} submit>
            {f({ id: 'search' })}
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

export default ExtendedDialog
