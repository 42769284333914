import * as httpCodes from 'http-status-codes'
import { all, call, put, take, takeLatest, takeLeading } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import * as apiService from '../../api'
import * as snackService from '../../snack'
import { watchAllowed } from './allowed.sagas'

function* workIndex() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.assortment.index,
      responseAction: actions.indexResponse,
    })
  )
}

function* workSingle(action: ReturnType<typeof actions.singleRequest>) {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.assortment.single(action.payload),
      responseAction: actions.singleResponse,
      expectedStatus: [httpCodes.SERVICE_UNAVAILABLE],
    })
  )

  const responseAction: ReturnType<typeof actions.singleResponse> = yield take(
    actions.singleResponse
  )

  if (responseAction.payload.status === httpCodes.SERVICE_UNAVAILABLE) {
    yield put(
      snackService.actions.createSnack({
        content: responseAction.payload.data.content,
        context: 'error',
      })
    )
  }
}

function* workCreate(action: ReturnType<typeof actions.createRequest>) {
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.assortment.create(action.payload.id),
      body: {
        name: action.payload.name,
        description: action.payload.description,
      },
      responseAction: actions.createResponse,
      expectedStatus: [httpCodes.BAD_REQUEST],
    })
  )

  const responseAction: ReturnType<typeof actions.createResponse> = yield take(
    actions.createResponse
  )

  yield call(action.payload.callback, responseAction.payload)

  if (responseAction.payload.status === httpCodes.OK) {
    yield put(actions.indexRequest())
  }
}

function* workUpdate(action: ReturnType<typeof actions.updateRequest>) {
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.assortment.update(action.payload.id),
      body: action.payload,
      responseAction: actions.updateResponse,
      expectedStatus: [httpCodes.BAD_REQUEST],
    })
  )

  const responseAction: ReturnType<typeof actions.updateResponse> = yield take(
    actions.updateResponse
  )

  yield call(action.payload.callback, responseAction.payload)

  if (responseAction.payload.status === httpCodes.NO_CONTENT) {
    yield put(actions.indexRequest())
  }
}

function* workDelete(action: ReturnType<typeof actions.deleteRequest>) {
  yield put(
    apiService.actions.del({
      endpoint: API_CONSTANT_MAP.assortment.delete(action.payload.id),
      responseAction: actions.deleteResponse,
      expectedStatus: [httpCodes.BAD_REQUEST],
    })
  )

  const responseAction: ReturnType<typeof actions.deleteResponse> = yield take(
    actions.deleteResponse
  )

  if (responseAction.payload.status === httpCodes.NO_CONTENT) {
    yield put(actions.indexRequest())
    yield take(actions.indexResponse)
  }

  yield call(action.payload.callback, responseAction.payload)
}

function* watch() {
  yield takeLatest(actions.indexRequest, workIndex)
  yield takeLeading(actions.createRequest, workCreate)
  yield takeLeading(actions.updateRequest, workUpdate)
  yield takeLeading(actions.singleRequest, workSingle)
  yield takeLeading(actions.deleteRequest, workDelete)
}

export function* main() {
  yield all([watch(), watchAllowed()])
}
