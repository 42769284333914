import { select, StrictEffect, take } from 'redux-saga/effects'
import { ActionCreator } from 'typesafe-actions'
import { RootState } from '../services/store'

export type SagaGenerator<T = any> = Generator<StrictEffect, T, T>

export function* takeAction<T extends ActionCreator>(action: T): SagaGenerator<ReturnType<T>> {
  return yield take(action)
}

export function* selectSelector<S>(
  selector: (store: RootState) => S
): SagaGenerator<ReturnType<typeof selector>> {
  return yield select(selector)
}
