import produce from 'immer'
import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'
import { actions } from '..'
import { Action, Location } from '../types'

export interface State {
  index: Location[]
}

const initialState: State = {
  index: [],
}

const main = createReducer<State, Action>(initialState).handleAction(
  actions.indexRes,
  (state, action) =>
    produce(state, draft => {
      draft.index = action.payload.data
    })
)

export default combineReducers({ main })
