import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { FC } from 'react'

export const LoadingPage: FC = props => {
  return (
    <Box width="100%" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}

export default LoadingPage
