import copy from 'copy-to-clipboard'
import * as httpCodes from 'http-status-codes'
import { createIntl } from 'react-intl'
import { all, call, put, select, take, takeLatest, takeLeading } from 'redux-saga/effects'
import { actions, selectors } from '.'
import { API_CONSTANT_MAP } from '../../helpers/constants'
import { messages } from '../../i18n/en'
import { takeAction } from '../../types'
import * as apiService from '../api'
import * as snackService from '../snack'
import { IQuery } from './types'

const intl = createIntl({
  locale: process.env.REACT_APP_LOCALE,
  messages,
})

const f = intl.formatMessage

function* workSearch(action: ReturnType<typeof actions.searchRequest>) {
  const indexQuery: ReturnType<typeof selectors.getIndexQuery> = yield select(
    selectors.getIndexQuery
  )

  const query: IQuery = {
    ...action.payload,
    references: indexQuery.references,
    language: indexQuery.language,
    pageSize: indexQuery.pageSize,
  }

  yield put(actions.updateIndexQuery(query))
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.product.search,
      body: query,
      responseAction: actions.searchResponse,
      expectedStatus: [httpCodes.BAD_REQUEST],
    })
  )

  const resAction = yield* takeAction(actions.searchResponse)

  if (resAction.payload.status === httpCodes.BAD_REQUEST) {
    yield put(
      snackService.actions.createSnack({
        context: 'error',
        content: f({ id: resAction.payload.data.content }),
      })
    )
  }
}

function* workSearchWithReferences(action: ReturnType<typeof actions.searchWithReferencesRequest>) {
  const indexQuery: ReturnType<typeof selectors.getIndexQuery> = yield select(
    selectors.getIndexQuery
  )

  const query: IQuery = {
    facets: undefined,
    term: undefined,
    references: action.payload,
    language: indexQuery.language,
    pageSize: indexQuery.pageSize,
  }

  yield put(actions.updateIndexQuery(query))
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.product.search,
      body: query,
      responseAction: actions.searchWithReferencesResponse,
    })
  )
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.product.delta,
      body: query,
      responseAction: actions.deltaReferencesResponse,
    })
  )
}

function* workLoadMore(action: ReturnType<typeof actions.loadMoreRequest>) {
  const indexQuery: ReturnType<typeof selectors.getIndexQuery> = yield select(
    selectors.getIndexQuery
  )

  const query: IQuery = {
    ...indexQuery,
    searchAfter: action.payload,
  }

  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.product.search,
      body: query,
      expectedStatus: [httpCodes.NOT_FOUND],
      responseAction: actions.loadMoreResponse,
    })
  )
}

function* workSingle(action: ReturnType<typeof actions.singleRequest>) {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.product.single(action.payload.reference),
      responseAction: actions.singleResponse,
      expectedStatus: [httpCodes.NOT_FOUND],
    })
  )

  let found = false
  while (!found) {
    const actionResponse: ReturnType<typeof actions.singleResponse> = yield take(
      actions.singleResponse
    )

    if (
      action.payload.reference === actionResponse.payload.data.raw?.hits[0]?._source?.id ||
      actionResponse.payload.data.title === 'Not Found'
    ) {
      found = true
    }

    if (found) {
      switch (actionResponse.payload.status) {
        case httpCodes.OK:
          yield put(actions.setSingle(actionResponse.payload.data))
          break
        case httpCodes.NOT_FOUND:
          yield call(action.payload.notFoundCallback)
          break
      }
    }
  }
}

function* workPreview(action: ReturnType<typeof actions.previewRequest>) {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.product.preview(action.payload.ref),
      responseAction: actions.previewResponse,
    })
  )

  let found = false

  while (!found) {
    const actionResponse: ReturnType<typeof actions.previewResponse> = yield take(
      actions.previewResponse
    )

    if (actionResponse.payload.data.ref) {
      found = actionResponse.payload.data.ref === action.payload.ref
    } else {
      found = true
    }

    yield found && call(action.payload.callback, actionResponse.payload)
  }
}

function* workCopy(action: ReturnType<typeof actions.copyRequest>) {
  const indexQuery: ReturnType<typeof selectors.getIndexQuery> = yield select(
    selectors.getIndexQuery
  )

  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.product.copy,
      body: indexQuery,
      responseAction: actions.copyResponse,
    })
  )

  const responseAction: ReturnType<typeof actions.copyResponse> = yield take(actions.copyResponse)

  yield call(copy, responseAction.payload.data)

  yield put(
    snackService.actions.createSnack({
      content: f(
        { id: 'any_references_copied_to_clipboard' },
        {
          count: responseAction.payload.data.length,
        }
      ),
      context: 'info',
    })
  )
}

function* watch() {
  yield takeLeading(actions.searchRequest, workSearch)
  yield takeLeading(actions.searchWithReferencesRequest, workSearchWithReferences)
  yield takeLeading(actions.loadMoreRequest, workLoadMore)
  yield takeLatest(actions.singleRequest, workSingle)
  yield takeLatest(actions.previewRequest, workPreview)
  yield takeLatest(actions.copyRequest, workCopy)
}

export function* main() {
  yield all([watch()])
}
