import { all, put, takeLatest } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import * as apiService from '../../api'

export function* watchIndex() {
  yield takeLatest(actions.indexReq, function* () {
    yield put(
      apiService.actions.get({
        endpoint: API_CONSTANT_MAP.location.index,
        responseAction: actions.indexRes,
      })
    )
  })
}

export function* main() {
  yield all([watchIndex()])
}
