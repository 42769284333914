import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import DialogActions from '../../components/DialogActions'
import * as selfService from '../../services/self'
import { useStyles } from './styles'

interface IProps {
  onClose: () => void
}

const ProfileDialog: FC<IProps> = props => {
  const selfInfo = useSelector(selfService.selectors.getSelfInfo)

  const { formatMessage: f } = useIntl()

  const fields = [
    {
      label: f({ id: 'first_name' }),
      value: selfInfo?.firstName,
    },
    {
      label: f({ id: 'last_name' }),
      value: selfInfo?.lastName,
    },
    {
      label: f({ id: 'email' }),
      value: selfInfo?.email,
    },
    {
      label: f({ id: 'role' }),
      value: selfInfo?.role.name,
    },
  ]

  const classes = useStyles()

  return (
    <form>
      <DialogContent>
        {fields.map((field, index) => (
          <TextField
            key={index}
            className={classes.field}
            InputProps={{
              readOnly: true,
            }}
            label={field.label}
            margin="dense"
            value={field.value}
            variant="outlined"
            fullWidth
          />
        ))}
      </DialogContent>
      <DialogActions onClose={props.onClose} noCta cancelTerm={f({ id: 'close' })} />
    </form>
  )
}

export default ProfileDialog
