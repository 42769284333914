import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import toMaterialStyle from 'material-color-hash'

interface IStyleProps {
  initials: string
  selfIsAdmin: boolean
}

const richemontHex = '#004165'

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    transition: theme.transitions.create('background-color'),
    backgroundColor: richemontHex,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  activeIcon: {
    backgroundColor: fade(theme.palette.common.white, 0.15),
    pointerEvents: 'none',
  },
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 70,
  },
  logo: {
    marginTop: 2,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    maxWidth: 320,
    width: '100%',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
    top: 5,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  meIcon: {
    background: (props: IStyleProps) => toMaterialStyle(props.initials, 700).backgroundColor,
    '&:hover': {
      background: (props: IStyleProps) => toMaterialStyle(props.initials, 700).backgroundColor,
    },
    color: (props: IStyleProps) => toMaterialStyle(props.initials, 700).color,
    marginLeft: theme.spacing(2),
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: fade(theme.palette.common.white, 0.5),
    padding: theme.spacing(1.25),
    textOverflow: 'ellipsis',
    transition: theme.transitions.create(['color', 'max-width']),
    '&::placeholder': {
      color: theme.palette.common.white,
    },
    '&:focus': {
      color: theme.palette.common.white,
    },
  },
  banner: {
    transition: theme.transitions.create('background-color'),
    backgroundColor: richemontHex,
    height: 160,
    width: '100%',
  },
  exitIcon: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(1),
  },
}))
