import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import MoreVert from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import React, { Component, createRef } from 'react'
import { styles } from './Dropdown.styles'
import { Props, State } from './Dropdown.types'

class Dropdown extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this._toggle = this._toggle.bind(this)

    this.state = { open: false }
  }

  private readonly _buttonRef = createRef<HTMLButtonElement>()

  render() {
    return (
      <div>
        <IconButton
          aria-label="dropdown"
          className={this.props.className}
          color={this.props.color}
          disabled={this.props.disabled}
          edge="end"
          onClick={this._toggle}
          ref={this._buttonRef}
          size={this.props.size}
        >
          {this.props.classes.children ? (
            <span className={this.props.classes.children}>{this.props.children}</span>
          ) : this.props.icon ? (
            React.createElement(this.props.icon)
          ) : (
            <MoreVert />
          )}
        </IconButton>
        <Menu
          PaperProps={{ className: this.props.classes.paper }}
          open={this.state.open}
          onClose={this._toggle}
          anchorEl={this._buttonRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {this.props.items &&
            this.props.items.map((item, index) => {
              const classNames = {
                menuItem: clsx(this.props.classes.menuItem, {
                  [this.props.classes.secondary]: item.secondary
                })
              }

              return item.divider ? (
                <Divider key={index} />
              ) : (
                <MenuItem
                  className={classNames.menuItem}
                  key={index}
                  onClick={_ => {
                    item.onClick && item.onClick()
                    this.setState({ open: false })
                  }}
                >
                  {item.title}
                </MenuItem>
              )
            })}
        </Menu>
      </div>
    )
  }

  _toggle() {
    this.setState({ open: !this.state.open })
  }
}

export default withStyles(styles)(Dropdown)
