import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { Assortment } from '../types'

export * from './allowed.actions'

export const indexRequest = createAction('🏷️ ASSORTMENT / INDEX => REQUEST')()
export const indexResponse = createAction('🏷️ ASSORTMENT / INDEX => RESPONSE')<
  AxiosResponse<Assortment>
>()

export const singleRequest = createAction('🏷️ ASSORTMENT / SINGLE => REQUEST')<number>()
export const singleResponse = createAction('🏷️ ASSORTMENT / SINGLE => RESPONSE')<AxiosResponse>()
export const singleReset = createAction('🏷️ ASSORTMENT / SINGLE => RESET')()

export const createRequest = createAction('🏷️ ASSORTMENT / CREATE => REQUEST')<{
  id: number
  name: string
  description: string
  callback: (response: AxiosResponse) => void
}>()
export const createResponse = createAction('🏷️ ASSORTMENT / CREATE => RESPONSE')<AxiosResponse>()

export const updateRequest = createAction('🏷️ ASSORTMENT / UPDATE => REQUEST')<{
  id: number
  name?: string
  description?: string
  references?: string[]
  propagate?: boolean
  callback: (response: AxiosResponse) => void
}>()
export const updateResponse = createAction('🏷️ ASSORTMENT / UPDATE => RESPONSE')<AxiosResponse>()

export const deleteRequest = createAction('🏷️ ASSORTMENT / DELETE => REQUEST')<{
  id: number
  callback: (response: AxiosResponse) => void
}>()
export const deleteResponse = createAction('🏷️ ASSORTMENT / DELETE => RESPONSE')<AxiosResponse>()
