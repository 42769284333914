import MuiAppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MeIcon from '@material-ui/icons/AccountCircle'
import CloudIcon from '@material-ui/icons/CloudDownload'
import ExitIcon from '@material-ui/icons/ExitToApp'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import SearchIcon from '@material-ui/icons/Search'
import CartIcon from '@material-ui/icons/ShoppingCart'
import { Form, Formik } from 'formik'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link, NavLink, RouteComponentProps } from 'react-router-dom'
import { useMount } from 'react-use'
import Dialog from '../../components/Dialog'
import Dropdown from '../../components/Dropdown'
import ElevationScroll from '../../components/ElevationScroll'
import Logo from '../../components/Logo'
import { useDispatchAlteredQuery } from '../../hooks/useDispatchAlteredQuery'
import * as bundleService from '../../services/bundle'
import * as cartService from '../../services/cart'
import * as catalogService from '../../services/catalog'
import * as selfService from '../../services/self'
import ContactDialog from '../ContactDialog'
import DownloadDialog from '../DownloadDialog'
import ExtendedDialog from '../ExtendedDialog'
import NotFoundReferencesDialog from '../NotFoundReferencesDialog/NotFoundReferencesDialog'
import ProfileDialog from '../ProfileDialog'
import TermsDialog from '../TermsDialog'
import { useStyles } from './styles'

type TProps = RouteComponentProps

const AppBar: FC<TProps> = props => {
  const theme = useTheme()

  const mediaIsXsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const mediaIsSmUp = useMediaQuery(theme.breakpoints.up('md'))

  const { formatMessage: f } = useIntl()

  const dispatch = useDispatch()
  const selfInfo = useSelector(selfService.selectors.getSelfInfo)
  const selfIsAdmin = useSelector(selfService.selectors.getSelfIsAdmin)
  const selfHasBundler = useSelector(selfService.selectors.getSelfHasBundler)
  const selfIsExternal = useSelector(selfService.selectors.getSelfIsExternal)
  const cart = useSelector(cartService.selectors.getCart)
  const cartIsProcessing = useSelector(cartService.selectors.getIsProcessing)
  const processing = useSelector(bundleService.selectors.getProcessing)
  const catalogIndexQuery = useSelector(catalogService.selectors.getIndexQuery)
  const notFoundReferences = useSelector(catalogService.selectors.getIndexNotFoundReferences)
  const hasNewBundle = useSelector(bundleService.selectors.getHasNew)

  const [profileDialogIsOpen, setProfileDialogIsOpen] = useState(false)
  const [downloadDialogIsOpen, setDownloadDialogIsOpen] = useState(false)
  const [extendedDialogIsOpen, setExtendedDialogIsOpen] = useState(false)
  const [termDialogIsOpen, setTermDialogIsOpen] = useState(false)
  const [contactDialogIsOpen, setContactDialogIsOpen] = useState(false)

  const history = useHistory()

  const dispatchAlteredQuery = useDispatchAlteredQuery(
    catalogIndexQuery,
    catalogService.actions.searchRequest
  )

  useMount(() => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0]
    badge?.parentElement?.removeChild(badge)
  })

  const initials = selfInfo ? `${selfInfo.firstName[0]}${selfInfo.lastName[0]}` : ''

  const classes = useStyles({
    initials,
    selfIsAdmin,
  })

  return (
    <>
      <ElevationScroll {...props}>
        <MuiAppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Box display="flex" alignItems="center">
              {/* <NavSection /> */}
              {mediaIsSmUp && (
                <Link to="catalog">
                  <Logo className={classes.logo} logo="RIC" color="white" size={0.45} />
                </Link>
              )}
            </Box>
            <Box
              className={classes.search}
              display="flex"
              alignItems="center"
              ml={mediaIsXsDown ? 0 : mediaIsSmUp ? -16 : 14}
            >
              <Formik
                initialValues={{ term: '' }}
                onSubmit={(values, actions) => {
                  if (values.term === '') {
                    return
                  }

                  props.location.pathname !== '/catalog' && history.push('/catalog')
                  dispatchAlteredQuery.withTermUpdated(values.term)
                  actions.resetForm()
                }}
              >
                {form => (
                  <Form>
                    <InputBase
                      onChange={event => form.setFieldValue('term', event.currentTarget.value)}
                      placeholder={
                        mediaIsXsDown
                          ? f({ id: 'search_ellipsis' })
                          : f({ id: 'search_in_catalog_ellipsis' })
                      }
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{
                        'aria-label': 'search in catalog',
                      }}
                      value={form.values.term}
                    />
                    <div className={classes.searchIcon}>
                      {form.values.term && (
                        <IconButton
                          aria-label="search"
                          color="inherit"
                          onClick={_ => {
                            form.submitForm()
                          }}
                          size="small"
                          style={{ marginRight: theme.spacing(0.5) }}
                        >
                          <SearchIcon />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label="extended search"
                        color="inherit"
                        size="small"
                        style={{ marginRight: theme.spacing(1) }}
                        onClick={_ => setExtendedDialogIsOpen(true)}
                      >
                        <MoreIcon />
                      </IconButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
            <Box display="flex" alignItems="center">
              {selfHasBundler && (
                <>
                  <Tooltip title={f({ id: 'selection' })}>
                    <IconButton
                      className={classes.icon}
                      activeClassName={classes.activeIcon}
                      color="inherit"
                      component={NavLink}
                      to="/cart"
                      size={mediaIsXsDown ? 'small' : 'medium'}
                    >
                      <Badge
                        badgeContent={
                          cartIsProcessing || cart.isMassProcessing ? (
                            <CircularProgress color="inherit" size={12} thickness={6} />
                          ) : (
                            cart.length
                          )
                        }
                        color="secondary"
                        max={999}
                      >
                        <CartIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={f({ id: 'downloads' })}>
                    <IconButton
                      className={classes.icon}
                      color="inherit"
                      size={mediaIsXsDown ? 'small' : 'medium'}
                      onClick={() => {
                        setDownloadDialogIsOpen(true)
                      }}
                    >
                      <Badge
                        badgeContent={
                          processing ? (
                            <CircularProgress color="inherit" size={12} thickness={6} />
                          ) : hasNewBundle ? (
                            1
                          ) : null
                        }
                        color="secondary"
                        max={999}
                      >
                        <CloudIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Dropdown
                className={classes.meIcon}
                color="inherit"
                icon={MeIcon}
                size="small"
                items={[
                  {
                    title: f({ id: 'profile' }),
                    onClick() {
                      setProfileDialogIsOpen(true)
                    },
                  },
                  {
                    title: f({ id: 'terms_and_conditions' }),
                    onClick() {
                      setTermDialogIsOpen(true)
                    },
                  },
                  {
                    title: f({ id: 'contact_or_support' }),
                    onClick() {
                      setContactDialogIsOpen(true)
                    },
                  },
                  {
                    divider: true,
                  },
                  {
                    title: (
                      <Box display="flex">
                        <ExitIcon className={classes.exitIcon} />
                        {f({ id: 'log_out' })}
                      </Box>
                    ),
                    onClick: () => {
                      dispatch(selfService.actions.logoutRequest())
                    },
                  },
                ]}
              >
                {initials}
              </Dropdown>
            </Box>
          </Toolbar>
        </MuiAppBar>
      </ElevationScroll>
      <Box className={classes.banner} />

      <Dialog
        isOpen={profileDialogIsOpen}
        onClose={() => setProfileDialogIsOpen(false)}
        title={f({ id: 'profile' })}
      >
        <ProfileDialog onClose={() => setProfileDialogIsOpen(false)} />
      </Dialog>
      <DownloadDialog
        isOpen={downloadDialogIsOpen}
        onClose={() => setDownloadDialogIsOpen(false)}
      />
      <Dialog
        title={f({ id: 'search_with_references' })}
        isOpen={extendedDialogIsOpen}
        maxWidth="md"
        onClose={() => setExtendedDialogIsOpen(false)}
      >
        <ExtendedDialog onClose={() => setExtendedDialogIsOpen(false)} />
      </Dialog>
      <Dialog
        title={f({ id: 'the_following_references_could_not_be_found' })}
        isOpen={notFoundReferences.length > 0}
        maxWidth="md"
        onClose={() => dispatch(catalogService.actions.clearNotFoundReferences())}
      >
        <NotFoundReferencesDialog
          onClose={() => dispatch(catalogService.actions.clearNotFoundReferences())}
          references={notFoundReferences}
        />
      </Dialog>
      <TermsDialog isOpen={termDialogIsOpen} setIsOpen={setTermDialogIsOpen} />
      <ContactDialog isOpen={contactDialogIsOpen} setIsOpen={setContactDialogIsOpen} />
    </>
  )
}

export default AppBar
