import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { useStyles } from './styles'

const DeprecatedBrowserBar: FC = _ => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="body2">
        Please note that you are using a deprecated browser and experience can be affected as a
        result. We recommended to switch on{' '}
        <a href="https://www.microsoft.com/windows/microsoft-edge">Edge</a> which is already
        installed on Windows 10 version, <a href="https://www.google.com/chrome/">Google Chrome</a>,
        or <a href="https://www.mozilla.org/">Firefox</a>
      </Typography>
    </div>
  )
}

export default DeprecatedBrowserBar
