import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { FC, LazyExoticComponent, ReactElement, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Snackbar from '../components/Snackbar'
import * as apiService from '../services/api'
import * as selfService from '../services/self'
import AppBar from '../views/AppBar'
import ErrorPage from '../views/ErrorPage'
import LoadingPage from '../views/LoadingPage'
import PageSection from '../views/PageSection'

export interface IRoute {
  name: string
  path: string | string[]
  params?: string
  component: LazyExoticComponent<any>
  options?: {
    condition?: boolean
    noScroll?: boolean
  }
}

interface IProps {
  routes: IRoute[]
}

const Router: FC<IProps> = ({ routes }) => {
  const apiState = useSelector(apiService.selectors.getApiState)
  const selfInfo = useSelector(selfService.selectors.getSelfInfo)

  const manageRoutes = () => {
    if (apiState.status === 'DOWN') {
      return <Route render={() => <ErrorPage error={apiState.error} />} />
    }

    if (!selfInfo) {
      return <Route component={LoadingPage} />
    }

    return (
      <Route
        render={globalRouter => (
          <>
            <Route component={AppBar} />
            <Suspense fallback="">
              <PageSection {...globalRouter}>
                <Switch>
                  {routes.map(route => {
                    const xRoutes: ReactElement[] = []

                    if (
                      route.options?.condition === undefined ||
                      (route.options?.condition !== undefined && route.options?.condition)
                    ) {
                      const Component = route.component

                      xRoutes.push(
                        <Route
                          exact
                          path={route.path}
                          render={router => {
                            document.title = `Richemont Extranet Wholesales${
                              route.name ? ' | ' + route.name : ''
                            }${
                              router.match.params[route.params ?? '']
                                ? ' | ' + router.match.params[route.params ?? '']
                                : ''
                            }`

                            enableBodyScroll(document.body)
                            route.options?.noScroll && disableBodyScroll(document.body)

                            return <Component {...router} />
                          }}
                        />
                      )
                    }

                    return xRoutes
                  })}
                  <Redirect to="/" />
                </Switch>
              </PageSection>
            </Suspense>
          </>
        )}
      />
    )
  }

  return (
    <BrowserRouter key="router">
      <Route component={Snackbar} />
      {manageRoutes()}
    </BrowserRouter>
  )
}

export default Router
