import MuiDialogActions from '@material-ui/core/DialogActions'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import Button from '../Button'

type TProps = Partial<{
  cancelDisabled: boolean
  cancelTerm: string
  ctaError: boolean
  disabled: boolean
  loading: boolean
  noCancel: boolean
  noCta: boolean
  onClick: () => void
  onClose: () => void
  submit: boolean
}>

const DialogActions: FC<TProps> = props => {
  const { formatMessage: f } = useIntl()

  return (
    <MuiDialogActions>
      {!props.noCancel && (
        <Button
          disabled={props.cancelDisabled !== undefined ? props.cancelDisabled : props.disabled}
          onClick={props.onClose}
        >
          {props.cancelTerm || f({ id: 'cancel' })}
        </Button>
      )}
      {!props.noCta && (
        <Button
          color={props.ctaError ? 'secondary' : 'primary'}
          disabled={props.disabled}
          onClick={props.onClick}
          loading={props.loading}
          submit={props.submit}
          variant="contained"
        >
          {props.children}
        </Button>
      )}
    </MuiDialogActions>
  )
}

export default DialogActions
