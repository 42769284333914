import produce from 'immer'
import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'
import { actions } from '..'
import { Action } from '../types'
import { AssortmentPreview } from '../../assortment/types'
import { Role } from '../../role/types'
import { User } from '../../user/types'
import { Location } from '../../location/types'

export interface State {
  allowed: Partial<{
    assortments: AssortmentPreview[]
    locations: Location[]
    roles: Role[]
  }>
  info?: User
}

const initalState: State = {
  allowed: {
    assortments: undefined,
    locations: undefined,
    roles: undefined,
  },
  info: undefined,
}

const main = createReducer<State, Action>(initalState)
  .handleAction(actions.acceptLegal, state =>
    produce(state, draft => {
      if (draft.info) {
        draft.info.legalAccepted = true
      }
    })
  )
  .handleAction(actions.allowedAssortmentsResponse, (state, action) =>
    produce(state, draft => {
      draft.allowed.assortments = action.payload.data
    })
  )
  .handleAction(actions.allowedRolesResponse, (state, action) =>
    produce(state, draft => {
      draft.allowed.roles = action.payload.data
    })
  )
  .handleAction(actions.allowedLocationsResponse, (state, action) =>
    produce(state, draft => {
      draft.allowed.locations = action.payload.data
    })
  )
  .handleAction(actions.resetAllowed, state =>
    produce(state, draft => {
      draft.allowed.assortments = undefined
      draft.allowed.locations = undefined
    })
  )
  .handleAction(actions.authorize, (state, action) =>
    produce(state, draft => {
      draft.info = action.payload
    })
  )
  .handleAction(actions.unauthorize, state =>
    produce(state, draft => {
      draft.info && window.location.reload()
    })
  )
  .handleAction(actions.logoutResponse, state =>
    produce(state, _ => {
      window.location.reload()
    })
  )

export default combineReducers({ main })
