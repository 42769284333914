import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '.'
import { BUNDLE_STATUS_CONSTANT } from '../../helpers/constants'
import { IState, TAction } from './types'

const initialState: IState = {
  index: {
    isBusy: false,
    results: [],
  },
  languages: [],
  resolutions: [],
  fileFormats: [],
  processing: false,
  hasNew: false,
}

export default createReducer<IState, TAction>(initialState)
  .handleAction(actions.languagesResponse, (state, action) =>
    produce(state, draft => {
      draft.languages = action.payload.data
    })
  )
  .handleAction(actions.resolutionsResponse, (state, action) =>
    produce(state, draft => {
      draft.resolutions = action.payload.data
    })
  )
  .handleAction(actions.fileFormatsResponse, (state, action) =>
    produce(state, draft => {
      draft.fileFormats = action.payload.data
    })
  )
  .handleAction(actions.indexRequest, state =>
    produce(state, draft => {
      draft.index.isBusy = true
    })
  )
  .handleAction(actions.indexResponse, (state, action) =>
    produce(state, draft => {
      if (
        action.payload.data.filter(
          bundle =>
            bundle.status === BUNDLE_STATUS_CONSTANT.collecting ||
            bundle.status === BUNDLE_STATUS_CONSTANT.copying ||
            bundle.status === BUNDLE_STATUS_CONSTANT.waiting ||
            bundle.status === BUNDLE_STATUS_CONSTANT.zipping
        ).length > 0
      ) {
        draft.processing = true
      }

      draft.index.results = action.payload.data
      draft.index.isBusy = false
    })
  )
  .handleAction(actions.deleteRequest, (state, action) =>
    produce(state, draft => {
      draft.index.results = draft.index.results.filter(result => result.id !== action.payload)
    })
  )
  .handleAction(actions.singleResponse, (state, action) =>
    produce(state, draft => {
      for (const bundle of draft.index.results) {
        if (bundle.id === action.payload.data[0].id) {
          bundle.packageName = action.payload.data[0].packageName
          bundle.status = action.payload.data[0].status
          bundle.size = action.payload.data[0].size
          bundle.s3url = action.payload.data[0].s3url
        }
      }

      if (
        draft.index.results.filter(
          bundle =>
            bundle.status === BUNDLE_STATUS_CONSTANT.collecting ||
            bundle.status === BUNDLE_STATUS_CONSTANT.copying ||
            bundle.status === BUNDLE_STATUS_CONSTANT.waiting ||
            bundle.status === BUNDLE_STATUS_CONSTANT.zipping
        ).length === 0
      ) {
        draft.processing = false
      }
    })
  )
  .handleAction(actions.setHasNew, (state, action) =>
    produce(state, draft => {
      draft.hasNew = action.payload
    })
  )
