export * from './actions.types'
export * from './sagas.types'
export * from './selectors.types'

export interface IBaseQuery {
  term?: string
  facets?: IBaseFacet[]
}

export interface IBaseFacet {
  displayName: string
  filters: IBaseFilter[]
  key: string
}

export interface IBaseFilter {
  count: number
  displayName: string
  id: string
}
