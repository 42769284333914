import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { ICreateBody, IBundle } from './types'

export const languagesRequest = createAction('📦 BUNDLE / LANGUAGES => REQUEST')()
export const languagesResponse = createAction('📦 BUNDLE / LANGUAGES => RESPONSE')<
  AxiosResponse
>()

export const resolutionsRequest = createAction('📦 BUNDLE / RESOLUTIONS => REQUEST')()
export const resolutionsResponse = createAction('📦 BUNDLE / RESOLUTIONS => RESPONSE')<
  AxiosResponse
>()

export const fileFormatsRequest = createAction('📦 BUNDLE / FILE_FORMATS => REQUEST')()
export const fileFormatsResponse = createAction('📦 BUNDLE / FILE_FORMATS => RESPONSE')<
  AxiosResponse
>()

export const indexRequest = createAction('📦 BUNDLE / INDEX => REQUEST')()
export const indexResponse = createAction('📦 BUNDLE / INDEX => RESPONSE')<
  AxiosResponse<IBundle[]>
>()

export const createRequest = createAction('📦 BUNDLE / CREATE => REQUEST')<{
  body: ICreateBody
  callback: () => void
}>()
export const createResponse = createAction('📦 BUNDLE / CREATE => RESPONSE')<
  AxiosResponse
>()

export const deleteRequest = createAction('📦 BUNDLE / DELETE => REQUEST')<number>()

export const singleRequest = createAction('📦 BUNDLE / SINGLE => REQUEST')<number>()
export const singleResponse = createAction('📦 BUNDLE / SINGLE => RESPONSE')<
  AxiosResponse<IBundle[]>
>()

export const setHasNew = createAction('📦 BUNDLE / HAS_NEW => SET')<boolean>()
