import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { IBaseQuery } from '../../../types'
import { IMassAssortmentBody, IBody } from '../types'

export * from './new.actions'
export * from './verify.actions'

export const queryUpdate = createAction('👥 USER / QUERY => UPDATE')<IBaseQuery>()
export const searchRequest = createAction('👥 USER / SEARCH => REQUEST')<IBaseQuery>()
export const searchResponse = createAction('👥 USER / SEARCH => RESPONSE')<AxiosResponse>()

export const singleRequest = createAction('👥 USER / SINGLE => REQUEST')<number>()
export const singleResponse = createAction('👥 USER / SINGLE => RESPONSE')<AxiosResponse>()
export const singleClear = createAction('👥 USER / SINGLE => CLEAR')()

export const updateRequest = createAction('👥 USER / UPDATE => REQUEST')<{
  id: number
  body: IBody
  callback: (response: AxiosResponse) => void
}>()
export const updateResponse = createAction('👥 USER / UPDATE => RESPONSE')<AxiosResponse>()

export const deleteRequest = createAction('👥 USER / DELETE => REQUEST')<{
  id: number
  successMessage: string
  callback: (response: AxiosResponse) => void
}>()
export const deleteResponse = createAction('👥 USER / DELETE => RESPONSE')<AxiosResponse>()

export const massAssortmentsRequest = createAction('👥 USER / MASS_ASSORTMENTS => REQUEST')<{
  body: IMassAssortmentBody
  verb: 'ADD' | 'REMOVE'
  callback: (response: AxiosResponse) => void
}>()
export const massAssortmentsResponse = createAction('👥 USER / MASS_ASSORTMENTS => RESPONSE')<
  AxiosResponse
>()
