import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  container: {
    background: grey.A400,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    position: 'fixed',
    zIndex: 1301
  },
  content: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2)
  },
  close: {
    color: theme.palette.common.white
  }
}))
