import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '.'
import packageJson from '../../../package.json'
import { IState, TAction } from './types'

const initialState: IState = {
  isReady: false,
  name: packageJson.name,
  version: packageJson.version
}

export default createReducer<IState, TAction>(initialState).handleAction(actions.isReady, state =>
  produce(state, draft => {
    draft.isReady = true
  })
)
