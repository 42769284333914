import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { StateType } from 'typesafe-actions'
import * as apiService from './api'
import * as applicationService from './application'
import * as assortmentService from './assortment'
import * as bundleService from './bundle'
import * as cartService from './cart'
import * as catalogService from './catalog'
import * as cultureService from './culture'
import * as locationService from './location'
import * as roleService from './role'
import * as selfService from './self'
import * as snackService from './snack'
import * as userService from './user'

/**
 * Add reducers here.
 */
const mainReducer = combineReducers({
  api: apiService.reducer,
  application: applicationService.reducer,
  assortment: assortmentService.reducer,
  bundle: bundleService.reducer,
  cart: cartService.reducer,
  catalog: catalogService.reducer,
  culture: cultureService.reducer,
  location: locationService.reducer,
  role: roleService.reducer,
  self: selfService.reducer,
  snack: snackService.reducer,
  user: userService.reducer,
})

/**
 * Add sagas here.
 */
function* mainSaga() {
  yield all([
    apiService.sagas.main(),
    applicationService.sagas.main(),
    assortmentService.sagas.main(),
    bundleService.sagas.main(),
    cartService.sagas.main(),
    catalogService.sagas.main(),
    cultureService.sagas.main(),
    locationService.sagas.main(),
    roleService.sagas.main(),
    selfService.sagas.main(),
    snackService.sagas.main(),
    userService.sagas.main(),
  ])
}

const sagaMiddleware = createSagaMiddleware()
const isDevelopment = process.env.NODE_ENV === 'development'
const composeEnhancers = isDevelopment
  ? composeWithDevTools({
      serialize: true,
      trace: true,
    })
  : compose

export type RootState = StateType<typeof mainReducer>

export const store: Store<RootState> = createStore(
  mainReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(mainSaga)
