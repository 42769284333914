import { lighten, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    position: 'fixed',
    textAlign: 'center',
    top: 0,
    left: '50%',
    transform: 'TranslateX(-50%)',
    zIndex: 1101,
    '& a': {
      color: lighten(theme.palette.primary.main, 0.7)
    }
  }
}))
