import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { IBaseQuery } from '../../types'
import { IQuery } from './types'

export const setIndexResultsSelect = createAction(
  '⌚ CATALOG / INDEX_RESULTS => SET_SELECT'
)<boolean>()
export const setIndexResultSelect = createAction(
  '⌚ CATALOG / INDEX_RESULT => SET_SELECT'
)<{
  references: string[]
  select: boolean
}>()

export const setIsExtended = createAction('⌚ CATALOG / IS_EXTENDED => SET')<boolean>()

export const updateIndexQuery = createAction('⌚ CATALOG / INDEX_QUERY => UPDATE')<IQuery>()
export const clearIndexQueryReferences = createAction(
  '⌚ CATALOG / INDEX_QUERY => CLEAR_REFERENCES'
)()

export const searchRequest = createAction('⌚ CATALOG / SEARCH => REQUEST')<IBaseQuery>()
export const searchResponse = createAction('⌚ CATALOG / SEARCH => RESPONSE')<
  AxiosResponse
>()

export const searchWithReferencesRequest = createAction(
  '⌚ CATALOG / SEARCH_WITH_REFERENCES => REQUEST'
)<string>()
export const searchWithReferencesResponse = createAction(
  '⌚ CATALOG / SEARCH_WITH_REFERENCES => RESPONSE'
)<AxiosResponse>()

export const deltaReferencesResponse = createAction(
  '⌚ CATALOG / DELTA_REFERENCES => RESPONSE'
)<AxiosResponse<string[]>>()

export const loadMoreRequest = createAction('⌚ CATALOG / LOAD_MORE => REQUEST')<string>()
export const loadMoreResponse = createAction('⌚ CATALOG / LOAD_MORE => RESPONSE')<
  AxiosResponse
>()

export const singleRequest = createAction('⌚ CATALOG / SINGLE => REQUEST')<{
  reference: string
  notFoundCallback: () => void
}>()
export const singleResponse = createAction('⌚ CATALOG / SINGLE => RESPONSE')<
  AxiosResponse
>()
export const setSingle = createAction('⌚ CATALOG / SINGLE => SET')<any>()

export const resetSingle = createAction('⌚ CATALOG / SINGLE => RESET')()
export const resetIndexResults = createAction('⌚ CATALOG / INDEX_RESULTS => RESET')()

export const previewRequest = createAction('⌚ CATALOG / PREVIEW => REQUEST')<{
  ref: string
  callback: (response: AxiosResponse) => void
}>()
export const previewResponse = createAction('⌚ CATALOG / PREVIEW => RESPONSE')<
  AxiosResponse
>()

export const copyRequest = createAction('⌚ CATALOG / COPY => REQUEST')()
export const copyResponse = createAction('⌚ CATALOG / COPY => RESPONSE')<AxiosResponse>()

export const clearNotFoundReferences = createAction(
  '⌚ CATALOG / NOT_FOUND_REFERENCES => CLEAR'
)()
