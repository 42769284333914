import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import React, { ReactElement } from 'react'

type TProps = Partial<{
  window: () => Window
  children: React.ReactElement
}>

const ElevationScroll = (props: TProps) => {
  const { children, window } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  })

  return React.cloneElement(children as ReactElement, {
    elevation: trigger ? 4 : 0
  })
}

export default ElevationScroll
