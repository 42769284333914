import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import Dialog from '../../components/Dialog'
import DialogActions from '../../components/DialogActions'
import { useStyles } from './styles'

interface IProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ContactDialog: FC<IProps> = (props) => {
  const { formatMessage: f } = useIntl()

  const classes = useStyles()

  return (
    <Dialog
      title={f({ id: 'contact_or_support' })}
      isOpen={props.isOpen}
      onClose={() => props.isOpen && props.setIsOpen(false)}
    >
      <div>
        <DialogContent className={classes.root}>
          <DialogContentText className={classes.topEdito} variant="body2">
            {f({ id: 'for_any_questions_long' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          cancelTerm={f({ id: 'close' })}
          noCta
          onClose={() => props.setIsOpen(false)}
        />
      </div>
    </Dialog>
  )
}

export default ContactDialog
