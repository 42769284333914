import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  showMore: {
    marginBottom: theme.spacing(1)
  }
}))
