import httpCodes from 'http-status-codes'
import Cookies from 'js-cookie'
import qs from 'querystring'
import { all, call, put, select, take, takeLeading } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import * as apiService from '../../api'
import * as applicationService from '../../application'
import * as bundleService from '../../bundle'
import * as cartService from '../../cart'
import * as cultureService from '../../culture'
import * as locationService from '../../location'
import * as roleService from '../../role'

function* workAllowedAssortments() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.assortment.indexAllowed,
      responseAction: actions.allowedAssortmentsResponse,
    })
  )
}

function* workAllowedRoles() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.role.indexAllowed,
      expectedStatus: [httpCodes.FORBIDDEN],
      responseAction: actions.allowedRolesResponse,
    })
  )
}

function* workAllowedLocations() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.location.index,
      responseAction: actions.allowedLocationsResponse,
    })
  )
}

function* workAuthenticate(action: ReturnType<typeof actions.authenticateRequest>) {
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.user.v2.selfAuthenticate,
      body: action.payload.body,
      expectedStatus: [
        httpCodes.BAD_REQUEST,
        httpCodes.PARTIAL_CONTENT,
        httpCodes.TEMPORARY_REDIRECT,
      ],
      responseAction: actions.authenticateResponse,
    })
  )

  const responseAction: ReturnType<typeof actions.authenticateResponse> = yield take(
    actions.authenticateResponse
  )

  if (responseAction.payload.status === httpCodes.OK) {
    yield put(actions.authorize(responseAction.payload.data))
    yield put(cartService.actions.updateLength(responseAction.payload.data.cartLength))
    yield put(bundleService.actions.indexRequest())
  } else {
    yield call(action.payload.callback, responseAction.payload)
  }
}

function* workInfo() {
  if (window.location.hash.includes('id_token')) {
    const token = qs.parse(window.location.hash.slice(1))['id_token']

    Cookies.set('id_token', token)

    window.history.replaceState(null, '', window.location.href.split('#')[0])
  }

  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.user.self,
      expectedStatus: [httpCodes.UNAUTHORIZED],
      responseAction: actions.infoResponse,
    })
  )

  const responseAction: ReturnType<typeof actions.infoResponse> = yield take(actions.infoResponse)

  const appIsReady: ReturnType<typeof applicationService.selectors.getIsReady> = yield select(
    applicationService.selectors.getIsReady
  )

  if (responseAction.payload.status === httpCodes.OK) {
    yield put(actions.authorize(responseAction.payload.data))

    if (responseAction.payload.data.role.features.includes('users')) {
      yield put(roleService.actions.allowedReq())
      yield take(roleService.actions.allowedRes)
      yield put(cultureService.actions.indexReq())
      yield take(cultureService.actions.indexRes)
      yield put(locationService.actions.indexReq())
      yield take(locationService.actions.indexRes)
    }

    yield put(cartService.actions.updateLength(responseAction.payload.data.cartLength))
    yield put(bundleService.actions.indexRequest())
  }

  if (responseAction.payload.status === httpCodes.UNAUTHORIZED) {
    yield put(actions.unauthorize())
  }

  yield !appIsReady && put(applicationService.actions.isReady())
}

function* workLogout() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.user.selfLogout,
      responseAction: actions.logoutResponse,
    })
  )

  yield take(actions.logoutResponse)

  window.location.href = `${process.env.REACT_APP_AUTH_ENDPOINT}/logout?p=${
    process.env.REACT_APP_AUTH_POLICY
  }&post_logout_redirect_uri=${encodeURIComponent(process.env.REACT_APP_AUTH_REDIRECT_URI)}`
}

function* workLegal() {
  yield put(
    apiService.actions.put({
      endpoint: API_CONSTANT_MAP.user.selfLegal,
      body: {},
      responseAction: actions.updateLegalResponse,
    })
  )

  const responseAction: ReturnType<typeof actions.updateLegalResponse> = yield take(
    actions.updateLegalResponse
  )

  if (responseAction.payload.status === httpCodes.NO_CONTENT) {
    yield put(actions.acceptLegal())
  }
}

function* watchLegal() {
  yield take(actions.updateLegalRequest)
  yield call(workLegal)
}

function* watch() {
  yield takeLeading(actions.infoRequest, workInfo)
  yield takeLeading(actions.authenticateRequest, workAuthenticate)
  yield takeLeading(actions.allowedAssortmentsRequest, workAllowedAssortments)
  yield takeLeading(actions.allowedRolesRequest, workAllowedRoles)
  yield takeLeading(actions.allowedLocationsRequest, workAllowedLocations)
  yield take(actions.logoutRequest)
  yield call(workLogout)
}

export function* main() {
  yield all([watch(), watchLegal()])
}
