import { createSelector } from 'reselect'
import { RootState } from '../store'

export const getCart = (store: RootState) => store.cart
export const getIsProcessing = createSelector(
  getCart,
  cart => cart.articlesToProcess.length > 0
)
export const getCloseReferences = (reference: string) =>
  createSelector(
    getCart,
    cart => {
      if (!cart.index.results) return { prev: undefined, next: undefined }

      const product = cart.index.results.find(result => result.ref === reference)

      if (!product) return { prev: undefined, next: undefined }

      const indexOf = cart.index.results.indexOf(product)

      return {
        prev: indexOf - 1 < 0 ? undefined : cart.index.results[indexOf - 1].ref,
        next:
          indexOf + 1 === cart.index.results.length
            ? undefined
            : cart.index.results[indexOf + 1].ref
      }
    }
  )
