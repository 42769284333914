import 'core-js/stable'
import 'intl-pluralrules'
import React from 'react'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'
import App from './App'
import * as serviceWorker from './serviceWorker'

const appElement = document.getElementById('root')
ReactDOM.render(<App />, appElement)

serviceWorker.unregister()
