import { makeStyles, fade } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 3, 0, 0),
    maxHeight: '50vh',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-track': {
      background: fade(theme.palette.grey[200], 0.2)
    },
    '&::-webkit-scrollbar-thumb': {
      background: fade(theme.palette.text.primary, 0.2)
    }
  }
}))
