import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import httpCodes from 'http-status-codes'
import Cookies from 'js-cookie'
import qs from 'querystring'
import { call, put, takeEvery } from 'redux-saga/effects'
import { PayloadActionCreator } from 'typesafe-actions'
import { actions } from '..'
import { selectSelector } from '../../../types'
import * as selfService from '../../self'

const config = (params: unknown) => {
  const headers = { Authorization: `Bearer ${Cookies.get('id_token')}` }

  const config: AxiosRequestConfig = {
    params,
    headers,
  }

  return config
}

function* handleResponse(
  response: AxiosResponse,
  status?: number[],
  callback?: PayloadActionCreator<string, AxiosResponse>
) {
  if (
    response.status === httpCodes.OK ||
    response.status === httpCodes.CREATED ||
    response.status === httpCodes.ACCEPTED ||
    response.status === httpCodes.NO_CONTENT ||
    (status && status.indexOf(response.status) > -1)
  ) {
    yield callback && put(callback(response))
  } else {
    yield put(actions.statusIsDown(response))
  }
}

function* handleError(
  error: any,
  status?: number[],
  callback?: PayloadActionCreator<string, AxiosResponse>
) {
  if (!error.response) {
    yield put(actions.statusIsDown(error))
  }

  if (error.response.status === httpCodes.INTERNAL_SERVER_ERROR) {
    yield put(actions.statusIsDown(error))
  }

  if (error.response.status === httpCodes.UNAUTHORIZED) {
    const client_id = process.env.REACT_APP_AUTH_CLIENT_ID
    const code_challenge = 'b0c179d7d3536c3fba6b77b5d807b74d6f4b4878d574229194b6095f21e2c65b'
    const p = process.env.REACT_APP_AUTH_POLICY
    const redirect_uri = process.env.REACT_APP_AUTH_REDIRECT_URI

    if (window.location.search.includes('code')) {
      const code = qs.parse(window.location.search.slice(1))['code']
      const params = {
        client_id,
        code,
        code_verifier: code_challenge,
        grant_type: 'authorization_code',
        p,
        redirect_uri,
      }

      try {
        const response: AxiosResponse = yield call(
          axios.post,
          `${process.env.REACT_APP_AUTH_ENDPOINT}/token`,
          null,
          { params }
        )
        Cookies.set('id_token', response.data.id_token)

        window.location.href = window.location.href.split('?')[0]
      } catch (error) {}
    } else {
      const params = qs.stringify({
        client_id,
        code_challenge,
        code_challenge_method: 'plain',
        p,
        redirect_uri,
        response_type: 'code',
        scope: 'openid',
      })

      window.location.href = `${process.env.REACT_APP_AUTH_ENDPOINT}/authorize?${params}`
    }
  }

  yield call(handleResponse, error.response, status, callback)
}

export function* watchCall() {
  yield takeEvery(actions.get, function* (action) {
    try {
      const params = action.payload.params
      const response: AxiosResponse = yield call(
        axios.get,
        `${process.env.REACT_APP_DOMAIN_API}${action.payload.endpoint}`,
        config(params)
      )

      yield call(
        handleResponse,
        response,
        action.payload.expectedStatus,
        action.payload.responseAction
      )
    } catch (error) {
      yield call(handleError, error, action.payload.expectedStatus, action.payload.responseAction)
    }
  })
  yield takeEvery(actions.post, function* (action) {
    try {
      const body = action.payload.body
      const params = action.payload.params
      const response: AxiosResponse = yield call(
        axios.post,
        `${process.env.REACT_APP_DOMAIN_API}${action.payload.endpoint}`,
        body || {},
        config(params)
      )

      yield call(
        handleResponse,
        response,
        action.payload.expectedStatus,
        action.payload.responseAction
      )
    } catch (error) {
      yield call(handleError, error, action.payload.expectedStatus, action.payload.responseAction)
    }
  })
  yield takeEvery(actions.put, function* (action) {
    try {
      const body = action.payload.body
      const params = action.payload.params
      const response: AxiosResponse = yield call(
        axios.put,
        `${process.env.REACT_APP_DOMAIN_API}${action.payload.endpoint}`,
        body,
        config(params)
      )

      yield call(
        handleResponse,
        response,
        action.payload.expectedStatus,
        action.payload.responseAction
      )
    } catch (error) {
      yield call(handleError, error, action.payload.expectedStatus, action.payload.responseAction)
    }
  })
  yield takeEvery(actions.del, function* (action) {
    try {
      const params = action.payload.params
      const response: AxiosResponse = yield call(
        axios.delete,
        `${process.env.REACT_APP_DOMAIN_API}${action.payload.endpoint}`,
        config(params)
      )

      yield call(
        handleResponse,
        response,
        action.payload.expectedStatus,
        action.payload.responseAction
      )
    } catch (error) {
      yield call(handleError, error, action.payload.expectedStatus, action.payload.responseAction)
    }
  })
}
