import { combineReducers } from 'redux'
import { createReducer } from 'typesafe-actions'
import { Action } from '../types'
import allowed from './allowed.reducer'

export interface State {}

const initialState: State = {}

const main = createReducer<State, Action>(initialState)

export default combineReducers({ main, allowed })
