const BASE_API = '/api'

// Basic CRUD:

// index  <- GET all items
// single <- GET single item by id
// create <- POST an item
// search <- POST search items by query
// update <- PUT data to an item
// remove <- DELETE an item

/**
 * List all API endpoints
 */
export const API_CONSTANT_MAP = {
  location: {
    index: `${BASE_API}/countries`,
  },
  resolution: {
    index: `${BASE_API}/assetsresolutions`,
  },
  fileFormat: {
    index: `${BASE_API}/fileformats`,
  },
  languages: {
    index: `${BASE_API}/languages`,
  },
  bundle: {
    index: `${BASE_API}/packages/self`,
    create: `${BASE_API}/packages`,
    remove: (id: number) => `${BASE_API}/packages/${id}`,
  },
  assortment: {
    index: `${BASE_API}/assortments`,
    indexAllowed: `${BASE_API}/assortments/allowed`,
    single: (id: number) => `${BASE_API}/assortments/${id}`,
    create: (id: number) => `${BASE_API}/assortments/${id}/createChild`,
    update: (id: number) => `${BASE_API}/assortments/${id}`,
    delete: (id: number) => `${BASE_API}/assortments/${id}`,
  },
  culture: {
    index: `${BASE_API}/cultures`,
  },
  cart: {
    index: `${BASE_API}/userscarts/self`,
    copy: `${BASE_API}/userscarts/copy`,
    add: `${BASE_API}/userscarts`,
    remove: `${BASE_API}/userscarts`,
    addAll: `${BASE_API}/userscarts/all`,
    removeAll: `${BASE_API}/userscarts/all`,
  },
  user: {
    addAssortments: `${BASE_API}/users/addAssortments`,
    removeAssortments: `${BASE_API}/users/removeAssortments`,
    selfContact: `${BASE_API}/users/self/contact`,
    selfLegal: `${BASE_API}/users/self/legal`,
    selfAuthenticate: `${BASE_API}/users/authenticate`,
    selfLogout: `${BASE_API}/users/logout`,
    self: `${BASE_API}/users/self`,
    recoveryPassword: `${BASE_API}/users/password`,
    single: (id: number) => `${BASE_API}/users/${id}`,
    search: `${BASE_API}/users/search`,
    create: `${BASE_API}/users`,
    update: (id: number) => `${BASE_API}/users/${id}`,
    remove: (id: number) => `${BASE_API}/users/${id}`,
    verify: `${BASE_API}/users/verifyAccount`,
    v2: {
      selfAuthenticate: `${BASE_API}/users/v2/authenticate`,
      create: `${BASE_API}/users/v2`,
    },
  },
  role: {
    indexAllowed: `${BASE_API}/roles/allowed`,
  },
  product: {
    copy: `${BASE_API}/products/copy`,
    search: `${BASE_API}/products/search`,
    delta: `${BASE_API}/products/notfound`,
    preview: (reference: string) => `${BASE_API}/products/${reference}/preview`,
    single: (reference: string) => `${BASE_API}/products/${reference}`,
  },
}

/**
 * List all the creation status of a bundle
 */
export const BUNDLE_STATUS_CONSTANT = {
  waiting: 1,
  collecting: 2,
  zipping: 3,
  copying: 4,
  ready: 5,
  error: 10,
}
