import * as httpCodes from 'http-status-codes'
import { put, takeLatest } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import { takeAction } from '../../../types'
import * as apiService from '../../api'

export function* watchVerify() {
  yield takeLatest(actions.verifyReq, function* (action) {
    yield put(
      apiService.actions.get({
        endpoint: API_CONSTANT_MAP.user.verify,
        params: { email: action.payload.email },
        responseAction: actions.verifyRes,
        expectedStatus: [httpCodes.BAD_REQUEST, httpCodes.NOT_FOUND],
      })
    )

    let found = false

    while (!found) {
      const resAction = yield* takeAction(actions.verifyRes)

      if (resAction.payload.config.params.email === action.payload.email) {
        action.payload.callback(resAction.payload)

        found = true
      }
    }
  })
}
