import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  row: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 46,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  container: {
    marginRight: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  downloadButton: {
    textDecoration: 'none !important',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginRight: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  error: {
    color: theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  size: {
    marginRight: theme.spacing(2),
  },
  progress: {
    padding: theme.spacing(2, 0),
    width: '50%',
  },
}))
