import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '.'
import { IState, TAction } from './types'

const initialState: IState = {
  index: []
}

export default createReducer<IState, TAction>(initialState)
  .handleAction(actions.createSnack, (state, action) =>
    produce(state, draft => {
      draft.index.push(action.payload)
    })
  )
  .handleAction(actions.removeSnack, state =>
    produce(state, draft => {
      draft.index.shift()
    })
  )
