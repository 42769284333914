import { createSelector } from 'reselect'
import { RootState } from '../store'

export const getIndexIsExtended = (store: RootState) => store.catalog.index.isExtended
export const getIndexIsBusy = (store: RootState) => store.catalog.index.isBusy
export const getIndexIsCopying = (store: RootState) => store.catalog.index.isCopying
export const getIndexHasMore = (store: RootState) => store.catalog.index.hasMore
export const getIndexTotal = (store: RootState) => store.catalog.index.total
export const getIndexQuery = (store: RootState) => store.catalog.index.query
export const getIndexFacets = (store: RootState) => store.catalog.index.facets
export const getIndexResults = (store: RootState) => store.catalog.index.results
export const getIndexNotFoundReferences = (store: RootState) =>
  store.catalog.index.notFoundReferences
export const getSingleImages = (store: RootState) => store.catalog.single?.sortedImages ?? []
export const getSingleVideos = (store: RootState) => store.catalog.single?.sortedVideos ?? []
//export const getSingleRaw = (store: RootState) => store.catalog.single?.raw?.hits[0]._source
export const getSingleFormatted = (store: RootState) => store.catalog.single?.formatted

export const getIndexQueryIsEmpty = createSelector(getIndexQuery, indexQuery =>
  indexQuery.term || indexQuery.references
    ? false
    : indexQuery.facets
      ? indexQuery.facets.length === 0
      : true
)

export const getCloseReferences = (reference: string) =>
  createSelector(getIndexResults, indexResults => {
    if (!indexResults) return { prev: undefined, next: undefined }

    const product = indexResults.find(result => result.ref === reference)

    if (!product) return { prev: undefined, next: undefined }

    const indexOf = indexResults.indexOf(product)

    return {
      prev: indexOf - 1 < 0 ? undefined : indexResults[indexOf - 1].ref,
      next: indexOf + 1 === indexResults.length ? undefined : indexResults[indexOf + 1].ref
    }
  })
