import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React, { FC, useEffect, useState } from 'react'

type Props = {
  error: any
}

const ErrorPage: FC<Props> = ({ error }) => {
  const [title, setTitle] = useState('Sorry, Something went wrong.')
  const [subtitle, setSubtitle] = useState(
    "We're working on it and we'll get it fixed as soon as we can."
  )

  useEffect(() =>{
    if (error.data?.content === 'locked') {
      setTitle('You do not have access to the Extranet Wholesale')
      setSubtitle('Please reach out to your Maison contact for support.')
    }
  }, [])

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      textAlign="center"
    >
      <Box mb={1}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box mb={6}>
        <Typography color="textSecondary">{subtitle}</Typography>
      </Box>
      <Button onClick={() => window.location.reload()}>Reload the page</Button>
    </Box>
  )
}

export default ErrorPage
