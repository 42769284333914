import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import MuiSnackbar from '@material-ui/core/Snackbar'
import { TransitionProps } from '@material-ui/core/transitions'
import CloseIcon from '@material-ui/icons/Close'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Alert from '@material-ui/lab/Alert'
import React, { FC, SyntheticEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMeasure, useUpdateEffect } from 'react-use'
import * as snackService from '../../services/snack'
import { ISnack } from '../../services/snack/types'
import { useStyles } from './styles'

const SlideTransition: FC<TransitionProps> = props => {
  return <Slide {...props} direction="up" />
}

const Snackbar: FC = () => {
  const dispatch = useDispatch()
  const snacks = useSelector(snackService.selectors.getSnacks)

  const placeholder: ISnack = { content: '', context: 'info' }
  const [isOpen, setIsOpen] = useState(false)
  const [snackbar, setSnackbar] = useState(placeholder)
  const [isExtended, setIsExtended] = useState(false)

  useUpdateEffect(() => {
    setIsExtended(false)
    if (isOpen) {
      setIsOpen(false)
    } else {
      if (snacks.length === 0) {
        setSnackbar(placeholder)
      } else {
        setSnackbar(snacks[0])
        setIsOpen(true)
      }
    }
  }, [snacks.length])

  const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason !== 'clickaway') {
      setIsOpen(false)
    }
  }

  const [ref, { width }] = useMeasure()

  const classes = useStyles()

  return (
    <MuiSnackbar
      className={classes.root}
      autoHideDuration={snackbar.showMore ? undefined : 3000}
      onClose={handleClose}
      onExited={_ => {
        dispatch(snackService.actions.removeSnack())
      }}
      open={isOpen}
      TransitionComponent={SlideTransition}
    >
      <div className={classes.content}>
        <Collapse className={classes.showMore} in={isExtended} style={{ maxWidth: width + 32 }}>
          <Alert action={<></>} elevation={6} variant="filled" severity={snackbar.context}>
            <div>{snackbar.showMore?.content}</div>
          </Alert>
        </Collapse>
        <Alert
          ref={ref}
          action={
            <>
              {snackbar.showMore && (
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsExtended(!isExtended)
                  }}
                >
                  {isExtended ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              )}
              <IconButton color="inherit" size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          }
          elevation={6}
          variant="filled"
          severity={snackbar.context}
        >
          <div>{snackbar.content}</div>
        </Alert>
      </div>
    </MuiSnackbar>
  )
}

export default Snackbar
