import copy from 'copy-to-clipboard'
import * as httpCodes from 'http-status-codes'
import { createIntl } from 'react-intl'
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { actions, selectors } from '.'
import { API_CONSTANT_MAP } from '../../helpers/constants'
import { messages } from '../../i18n/en'
import * as apiService from '../api'
import * as catalogService from '../catalog'
import * as snackService from '../snack'
import { loadMoreRequest } from './actions'

const intl = createIntl({
  locale: process.env.REACT_APP_LOCALE,
  messages,
})

const f = intl.formatMessage

function* workIndex() {
  const cart: ReturnType<typeof selectors.getCart> = yield select(selectors.getCart)

  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.cart.index,
      params: { pageSize: cart.index.pageSize },
      responseAction: actions.indexResponse,
    })
  )
}

function* workLoadMore(action: ReturnType<typeof actions.loadMoreRequest>) {
  const cart: ReturnType<typeof selectors.getCart> = yield select(selectors.getCart)

  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.cart.index,
      params: { pageSize: cart.index.pageSize, searchAfter: action.payload },
      expectedStatus: [httpCodes.NOT_FOUND],
      responseAction: actions.loadMoreResponse,
    })
  )
}

function* workAddAll(action: ReturnType<typeof actions.addAllRequest>) {
  const catalogIndexQuery: ReturnType<typeof catalogService.selectors.getIndexQuery> = yield select(
    catalogService.selectors.getIndexQuery
  )

  const body = {
    references: catalogIndexQuery.references,
    term: action.payload.query.term,
    facets: action.payload.query.facets,
  }

  yield put(
    snackService.actions.createSnack({
      content: f({ id: 'adding_any_product' }, { count: action.payload.count }),
      context: 'info',
    })
  )

  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.cart.addAll,
      body: {
        ...body,
        language: process.env.REACT_APP_LOCALE,
      },
      responseAction: actions.addAllResponse,
    })
  )

  let found = false
  while (!found) {
    const responseAction: ReturnType<typeof actions.addAllResponse> = yield take(
      actions.addAllResponse
    )

    if (responseAction) {
      found =
        JSON.stringify(JSON.parse(responseAction.payload.config.data).facets) ===
        JSON.stringify(action.payload.query.facets)
    }

    if (found) {
      yield put(actions.updateLength(responseAction.payload.data.length))
    }
  }

  yield put(
    snackService.actions.createSnack({
      content: f({ id: 'successfully_added_any_product' }, { count: action.payload.count }),
      context: 'success',
    })
  )

  yield put(catalogService.actions.searchRequest(catalogIndexQuery))
  yield put(actions.indexRequest())
}

function* workRemoveAll() {
  yield put(
    apiService.actions.del({
      endpoint: API_CONSTANT_MAP.cart.removeAll,
      responseAction: actions.removeAllResponse,
    })
  )

  yield take(actions.removeAllResponse)

  yield put(actions.updateLength(0))
  yield put(catalogService.actions.setIndexResultsSelect(false))
}

function* workSingle(action: ReturnType<typeof actions.singleRequest>) {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.cart.index,
      params: { reference: action.payload },
      responseAction: actions.singleResponse,
    })
  )
}

function* workProcessArticle() {
  let cart: ReturnType<typeof selectors.getCart> = yield select(selectors.getCart)
  let removeOnly = true

  while (cart.articlesToProcess.length > 0) {
    if (cart.index.results.length < cart.index.pageSize / 2 && cart.index.hasMore) {
      yield put(loadMoreRequest(cart.index.results[cart.index.results.length - 1].ref))
    }

    const payload = cart.articlesToProcess[0]

    switch (payload.action) {
      case 'add':
        removeOnly = false
        yield put(
          snackService.actions.createSnack({
            content: f({ id: 'adding_any_product' }),
            context: 'info',
          })
        )
        yield put(
          apiService.actions.post({
            endpoint: API_CONSTANT_MAP.cart.add,
            body: [payload.article],
            responseAction: actions.addResponse,
          })
        )
        break
      case 'remove':
        yield put(
          apiService.actions.del({
            endpoint: API_CONSTANT_MAP.cart.remove,
            params: { codePim: payload.article.ref },
            responseAction: actions.removeResponse,
          })
        )
        break
      default:
        break
    }

    const responseAction: ReturnType<typeof actions.addResponse | typeof actions.removeResponse>[] =
      yield race([take(actions.addResponse), take(actions.removeResponse)])

    if (
      responseAction[0]?.type === '🛒 CART / ADD => RESPONSE' &&
      responseAction[0]?.payload.status === httpCodes.OK
    ) {
      yield put(
        snackService.actions.createSnack({
          content: f({ id: 'successfully_added_any_product' }),
          context: 'success',
        })
      )
    }

    yield put(actions.unqueueArticleToProcess())

    cart = yield select(selectors.getCart)
  }

  yield !removeOnly && put(actions.indexRequest())
}

function* workCopy() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.cart.copy,
      responseAction: actions.copyResponse,
    })
  )

  const responseAction: ReturnType<typeof actions.copyResponse> = yield take(actions.copyResponse)

  yield call(copy, responseAction.payload.data)

  yield put(
    snackService.actions.createSnack({
      content: f(
        { id: 'any_references_copied_to_clipboard' },
        {
          count: responseAction.payload.data.length,
        }
      ),
      context: 'info',
    })
  )
}

function* watch() {
  yield takeLatest(actions.indexRequest, workIndex)
  yield takeEvery(actions.loadMoreRequest, workLoadMore)
  yield takeLeading([actions.addRequest, actions.removeRequest], workProcessArticle)
  yield takeEvery(actions.addAllRequest, workAddAll)
  yield takeLeading(actions.removeAllRequest, workRemoveAll)
  yield takeEvery(actions.singleRequest, workSingle)
  yield takeLatest(actions.copyRequest, workCopy)
}

export function* main() {
  yield all([watch()])
}
