import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {},
  topEdito: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  }
}))
