import { createAction } from 'typesafe-actions'
import { AxiosResponse } from 'axios'
import { CallbackPayload } from '../../../types'
import { User } from '../types'
import { Error } from '../../api/types'

export const verifyReq = createAction('👥 USER / VERIFY => REQ')<
  CallbackPayload<User & Error> & {
    email: string
  }
>()
export const verifyRes = createAction('👥 USER / VERIFY => RES')<AxiosResponse<User & Error>>()
