import { PropTypes } from '@material-ui/core/'
import MuiButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import React, { createRef, CSSProperties, FC, useEffect, useState } from 'react'

type TProps = Partial<{
  className: string
  color: PropTypes.Color
  disabled: boolean
  id: string
  fullWidth: boolean
  loading: boolean
  onClick: () => void
  onMouseOver: () => void
  title: string
  size: 'small' | 'medium' | 'large'
  style: CSSProperties
  submit: boolean
  variant: 'text' | 'outlined' | 'contained'
}>

const Button: FC<TProps> = props => {
  const ref = createRef<HTMLButtonElement>()

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    ref.current && setWidth(ref.current.offsetWidth)
    ref.current && setHeight(ref.current.offsetHeight)
  }, [props.loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const getLoaderSize = () => {
    switch (props.size) {
      case 'small':
        return 22
      case 'large':
        return 26
      case 'medium':
      default:
        return 24
    }
  }

  return (
    <MuiButton
      className={props.className}
      color={props.color}
      disabled={props.disabled || props.loading}
      id={props.id}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      ref={ref}
      size={props.size}
      style={{
        ...props.style,
        width: !props.fullWidth && props.loading ? width : undefined,
        height: props.loading ? height : undefined
      }}
      title={props.title}
      type={props.submit ? 'submit' : undefined}
      variant={props.variant}
    >
      {(props.loading && (
        <CircularProgress
          color="inherit"
          size={getLoaderSize()}
          style={{ verticalAlign: 'middle' }}
        />
      )) ||
        props.children}
    </MuiButton>
  )
}

export default Button
