import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { CallbackPayload } from '../../../types'
import { AssortmentPreview } from '../types'

export const allowedReq = createAction('🏷️ ASSORTMENT / ALLOWED => REQ')<
  CallbackPayload<AssortmentPreview[]>
>()
export const allowedRes = createAction('🏷️ ASSORTMENT / ALLOWED => RES')<
  AxiosResponse<AssortmentPreview[]>
>()
