import * as httpCodes from 'http-status-codes'
import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '..'
import { Action, Assortment, AssortmentExtended } from '../types'
import { combineReducers } from 'redux'
import allowed from './allowed.reducer'

export interface State {
  isBusy: boolean
  index?: Assortment
  single?: AssortmentExtended
}

const initialState: State = {
  isBusy: false,
  index: undefined,
  single: undefined,
}

const main = createReducer<State, Action>(initialState)
  .handleAction([actions.indexRequest, actions.deleteRequest], state =>
    produce(state, draft => {
      draft.isBusy = true
    })
  )
  .handleAction(actions.indexResponse, (state, action) =>
    produce(state, draft => {
      draft.index = action.payload.data
      draft.isBusy = false
    })
  )
  .handleAction(actions.singleRequest, state =>
    produce(state, draft => {
      draft.single = undefined
      draft.isBusy = true
    })
  )
  .handleAction(actions.singleResponse, (state, action) =>
    produce(state, draft => {
      if (action.payload.status === httpCodes.OK) {
        draft.single = action.payload.data
      }
      draft.isBusy = false
    })
  )
  .handleAction(actions.singleReset, state =>
    produce(state, draft => {
      draft.single = undefined
    })
  )
  .handleAction(actions.updateRequest, (state, action) =>
    produce(state, draft => {
      const { name, description, references } = action.payload

      if (draft.single) {
        if (name) draft.single.name = name
        if (description) draft.single.description = description
        if (references) draft.single.references = references
      }

      draft.isBusy = action.payload.references ? action.payload.references?.length > 0 : false
    })
  )
  .handleAction([actions.updateResponse, actions.deleteResponse], state =>
    produce(state, draft => {
      draft.isBusy = false
    })
  )

export default combineReducers({ main, allowed })
