import DialogContent from '@material-ui/core/DialogContent'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../components/Dialog'
import DialogActions from '../../components/DialogActions'
import * as selfService from '../../services/self'
import { useStyles } from './styles'

interface IProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const TermsDialog: FC<IProps> = props => {
  const dispatch = useDispatch()
  const selfInfo = useSelector(selfService.selectors.getSelfInfo)

  const { formatMessage: f } = useIntl()

  const hasAccepted = selfInfo?.legalAccepted

  const classes = useStyles()

  return (
    <Dialog
      title={f({ id: 'terms_and_conditions' })}
      isOpen={!selfInfo?.legalAccepted || props.isOpen}
      maxWidth="lg"
      onClose={() => props.isOpen && props.setIsOpen(false)}
    >
      {actions => (
        <>
          <DialogContent className={classes.root} tabIndex={0}>
            <p>
              <strong>1. ABOUT THESE TERMS OF USE</strong>
            </p>
            <p>
              The B2B Extranet (hereinafter referred to as &ldquo;<strong>B2B Extranet</strong>
              &rdquo;) is a tool developed by Richemont International SA (Switzerland), which
              enables wholesalers, partners and authorised retailers as well as external boutiques
              of certain Richemont Maisons (the &ldquo;<strong>Maisons</strong>&rdquo;) to consult
              and download product information and digital assets, as well as to use the other
              functionalities offered by B2B Extranet.
            </p>
            <p>
              <strong>Authorized Partner </strong>(as defined in section 3 below) is interested in
              accessing and using B2B Extranet and agrees to comply at all times with the following
              terms and conditions (the "<strong>Terms of Use</strong>").
            </p>
            <p>
              <strong>
                Please read these Terms of Use carefully before using B2B Extranet. By using B2B
                Extranet, Authorized Partner signifies its assent and agreement to these Terms of
                Use.&nbsp; Authorized Partner represents and warrants that any user accepting these
                Terms of Use is authorized to do so on behalf of Authorized Partner. If Authorized
                Partner does not agree to these Terms of Use, then it is not authorized to continue
                use of B2B Extranet.&nbsp;{' '}
              </strong>
            </p>
            <p>
              <strong>
                For the avoidance of doubt, provision of B2B Extranet to the relevant Authorized
                Partner is made by the Richemont entity with which Authorized Partner has concluded
              </strong>{' '}
              <strong>
                the most current authorised wholesales or retailer agreement (the &ldquo;Authorized
                Dealer Agreement&rdquo;) or boutique agreement (the &ldquo;Boutique
                Agreement&rdquo;) (hereinafter referred to as &ldquo;Relevant Richemont
                Entity&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;) and such
                Relevant Richemont Entity shall be entity shall be entitled to enforce these Terms
                of Use. &nbsp;To the extent that there is any inconsistency between (i) the
                provisions of the Authorized Dealer Agreement or the Boutique Agreement and (ii)
                these Terms of Use, the provisions of the Authorized Dealer Agreement or the
                Boutique Agreement (as applicable) shall prevail.
              </strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>2. UPDATES TO THESE TERMS OF USE</strong>
            </p>
            <p>
              We may make changes from time to time to these Terms of Use. The latest version of
              these Terms of Use will always be available on B2B Extranet. Any new version of these
              Terms of Use shall take effect and will govern the use of B2B Extranet and Authorized
              Partner&rsquo;s relationship with us immediately upon the date of posting. By
              continuing to use B2B Extranet, Authorized Partner agrees to be bound by the terms of
              these updates and amendments.
            </p>
            <p>&nbsp;</p>
            <strong>3. GENERAL</strong>
            <ol>
              <li>
                Access to B2B Extranet is strictly limited to Relevant Richemont Entity&rsquo;s
                current authorized wholesalers, partners and retailers (as applicable) and external
                Relevant Richemont Entity boutiques (referred together as "
                <strong>Authorized Partner</strong>") which have signed the Relevant Richemont
                Entity&rsquo;s Authorized Dealer Agreement or the Boutique Agreement (as applicable)
                enters into and their authorized employees.
              </li>
              <li>
                Authorized Partner agrees to comply with any subsequent instructions relating to the
                access and/or use of B2B Extranet as may be issued by Relevant Richemont Entity from
                time to time. Relevant Richemont Entity may also decide that the use of all or part
                of B2B Extranet's functionality is mandatory. In this event, Relevant Richemont
                Entity will inform Authorized Partner in advance by way of a posting on B2B
                Extranet.
              </li>
              <li>
                B2B Extranet is a tool solely designed to facilitate Authorized Partner's business
                with Relevant Richemont Entity. Further, Authorized Partner remains solely
                responsible for managing its business. Prices mentioned on B2B Extranet are
                Recommended Sale Prices (VAT incl.). Recommended Sale Prices are indicative only and
                do not restrict Authorized Partner in any manner to determine its own customer
                prices, which shall be determined in Authorized Partner&rsquo;s sole discretion.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>4. ACCESS AND USE</strong>
            <ol>
              <li>
                <strong>Access:</strong> Authorized Partner hereby agrees and confirms that it will
                only request B2B Extranet access profiles from Relevant Richemont Entity for its own
                current employees, to the exclusion of any other person:
                <ul>
                  <li>
                    (i) Individual account profiles are created in the form of individual usernames
                    and passwords for each authorized employee and transmitted to Authorized Partner
                    by their local administrator, on behalf of Relevant Richemont Entity, thereby
                    allowing access to and use of B2B Extranet. All account profiles are strictly
                    personal to the current authorized employees of Authorized Partner and may not
                    be shared with/disclosed to any other party. Access to B2B Extranet shall always
                    occur from the authorized retail premises of the Authorized Partner. Authorized
                    Partner represents and warrants that any user who accepts these Terms of Use is
                    authorized to do so on behalf of Authorized Partner;
                  </li>
                  <li>
                    (ii) Authorized Partner shall be fully liable for any operation, misuse,
                    manipulation or any other act or omission performed under its account profiles,
                    including any unauthorized act undertaken by one of its officers, directors,
                    employees, agents (whether current or past) or any other third party who gains
                    access to B2B Extranet through use of the Authorized Partner&rsquo;s account
                    profiles;
                  </li>
                  <li>
                    (iii) Notwithstanding section 4.a (i) above, Authorized Partner further agrees
                    to:
                    <ul>
                      <li>
                        notify the local Relevant Richemont Entity administrator responsible for
                        managing Authorized Partner&rsquo;s account profile immediately in the event
                        that it suspects and/or becomes aware of any unauthorized use of the
                        Authorized Partner's account profile, including security breaches of
                        whatever nature (for example stolen account profile, circumvention or
                        hacking);
                      </li>
                      <li>
                        notify Relevant Richemont Entity prior to the termination of employment of
                        any authorized employee&rsquo;s employment with Authorized Partner so that
                        Relevant Richemont Entity can suspend said authorized employee&rsquo;s
                        access to B2B Extranet prior to such termination. Authorized Partner shall
                        comply with any instructions from Relevant Richemont Entity in order to
                        facilitate this process;
                      </li>
                      <li>
                        ensure that all Customer Data and Data (as such terms are defined in
                        sections 5 and 6 below) entered into B2B Extranet by Authorized Partner are
                        correct, truthful and complete and ensure that such Customer Data and Data
                        is kept updated by Authorized Partner;
                      </li>
                      <li>
                        ensure that Authorized Partner&rsquo;s access to B2B Extranet is always
                        provided using computer systems, hardware and software that have technical
                        tools and related staff operational procedures (including training and
                        awareness) that ensure that the Data and Customer Data are kept secure from
                        unauthorized access, disclosure, loss or damage; and
                      </li>
                      <li>
                        ensure that access to its computer terminals or mobile devices is restricted
                        to its authorized employees and that each of Authorized Partner's
                        employees&rsquo; logs out correctly at the end of each session from B2B
                        Extranet.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <p>&nbsp;</p>
              <li>
                <strong>Access suspension/termination:</strong> In the event that Relevant Richemont
                Entity reasonably believes, at its entire discretion, that the Authorized Partner
                and/or any of its employees did not comply and/or acted inconsistently with these
                Terms of Use and/or did not comply with the provisions of any agreement entered into
                between (i) Relevant Richemont Entity and/or any other affiliate of the Richemont
                group and (ii) Authorized Partner, and/or have violated Relevant Richemont Entity's
                rights and/or the rights of any other affiliate of the Relevant Richemont Entity,
                Relevant Richemont Entity shall be entitled to suspend and/or cancel all or part of
                Authorized Partner's access to B2B Extranet immediately. Moreover, upon termination
                of Authorized Partner&rsquo;s business relationship with Relevant Richemont Entity
                for any reason whatsoever, Relevant Richemont Entity will cancel Authorized
                Partner's access to B2B Extranet forthwith. Termination of Authorized
                Partner&rsquo;s right to use B2B Extranet for any reason whatsoever by Relevant
                Richemont Entity shall not give rise to any right of indemnity of whatever nature to
                Authorized Partner.
              </li>
              <p>&nbsp;</p>
              <li>
                <strong>Use and functionalities: </strong>B2B Extranet currently features the
                following main functionalities:
                <ul>
                  <li>
                    (i) <strong>Product catalog:</strong> view a list of authorized products related
                    to the relevant market;
                  </li>
                  <li>
                    (ii) <strong>Digital assets download:</strong> download product information and
                    digital assets;
                  </li>
                  <li>
                    (iii) <strong>Users access management: </strong>add, delete, update authorised
                    users which may gain access to the B2B Extranet;
                  </li>
                  <li>
                    (iv) <strong>Retailers management: </strong>add, update retailers;
                  </li>
                  <li>
                    (v) <strong>Assortments management: </strong>add, delete, update assortments.
                  </li>
                </ul>
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>5. USE OF AUTHORIZED PARTNER’S PERSONAL DATA</strong>
            <ol>
              <li>
                Authorized Partner and its employees using B2B Extranet hereby expressly consent to
                the collection by Relevant Richemont Entity of personal information and server log
                data (&ldquo;<strong>Data</strong>&rdquo;) relating to their use of B2B Extranet,
                such Data being automatically provided to Relevant Richemont Entity through tracking
                software (including cookies) when connecting to B2B Extranet and upon registration
                for use of the system.
              </li>
              <li>
                Relevant Richemont Entity uses this Data, in particular, to improve B2B Extranet and
                services and to fulfil any requests from Authorized Partner and/or to respond to any
                questions or comments they may choose to submit.
              </li>
              <li>
                Relevant Richemont Entity also uses this Data to survey use of B2B Extranet and
                prevent any possible abusive use of B2B Extranet.
              </li>
              <li>
                Furthermore, Relevant Richemont Entity may use personal information it has collected
                for normal business purposes and as a marketing tool to advise Authorized Partner of
                new products and/or events in relation to Relevant Richemont Entity.
              </li>
              <li>
                Authorized Partner is responsible for informing its employees of any collection of
                their personal data by Relevant Richemont Entity and where required by applicable
                laws, obtaining their consent to such collection and use. Authorized Partner further
                agrees to defend, hold harmless and fully indemnify Relevant Richemont Entity, its
                affiliates and their personnel, agents and officers against any damages and
                reasonable associated costs arising out of or in connection with any fines and/or
                penalties imposed by courts or regulators as a result of any data breaches in
                respect of the Data;
              </li>
              <li>
                Relevant Richemont Entity will take reasonable steps to store such Data securely
                although it will be made accessible for joint use by affiliates of the Relevant
                Richemont Entity and to any service providers engaged to process the Data on behalf
                of Relevant Richemont Entity. Save as otherwise specified in these Terms of Use,
                Relevant Richemont Entity does not otherwise share or disclose any such Data with
                third parties unless such disclosure is required by law or by a court/administrative
                decision.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>6. USE OF MATERIALS ON B2B EXTRANET</strong>
            <ol>
              <li>
                Whilst considerable effort has been made to ensure that the visual representations
                of Relevant Richemont Entity products displayed on B2B Extranet are representative
                of the colour, design and style etc. of the original products, slight variations,
                distortions and/or differences may be apparent when compared to the original
                product. This may, for example, be due to technical issues such as a browser or
                computer settings. Accordingly, Relevant Richemont Entity cannot be held liable for
                any apparent differences in the product images represented on B2B Extranet and the
                original products.
              </li>
              <li>
                Authorized Partner may download one computer copy or print one copy of the material
                made available via B2B Extranet, provided that proprietary notices, in particular
                intellectual property notices such as copyright&copy;, trademark&trade;, are
                preserved intact and are not modified, deleted or changed. Unless otherwise stated,
                Authorized Partner should assume that everything on B2B Extranet (such as images,
                photographs, including any person represented in the photographs, illustrations,
                icons, texts, video clips, music, written and other materials) ("
                <strong>Relevant Richemont Entity Material</strong>") are protected by legislation
                such as copyright, designs and trademark legislation and under international treaty
                provisions and national laws worldwide.
              </li>
              <li>
                Authorized Partner is not authorized to sell, reproduce, distribute, communicate,
                modify, display, publicly perform, report or otherwise prepare derivative or second
                hand works based on or use any Relevant Richemont Entity Material in any way for any
                public or commercial purposes. Furthermore, Relevant Richemont Entity Material may
                not be displayed or communicated on any other platform, in a networked computer
                environment or on any other digital platform for any purpose whatsoever. In the
                event of breach of any of these Terms of Use, the permission to use Relevant
                Richemont Entity Material will automatically terminate and any copies made of
                Relevant Richemont Entity Material must be immediately destroyed. Any unauthorized
                use of Relevant Richemont Entity Material may infringe copyright laws, trademark
                laws, the laws of privacy and publicity, and communications regulations and
                statutes.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>7. TRADEMARK NOTICE</strong>
            <p>
              In general, all trademarks, logos and service marks (collectively the "
              <strong>Trademarks</strong>") that appear on B2B Extranet are registered, unregistered
              or otherwise protected. Relevant Richemont Entity trademarks or are licensed for use
              by Relevant Richemont Entity by third parties. Other trademarks are proprietary marks
              and are registered to their respective owners. Nothing contained on B2B Extranet
              should be construed as granting, by implication or otherwise, any licence or right to
              use any trademark without Relevant Richemont Entity&rsquo;s prior written permission
              or that of such third party who owns the trademark. Misuse of any trademark displayed
              on B2B Extranet, or any other content on B2B Extranet, except as provided herein, is
              strictly prohibited.
            </p>
            <p>&nbsp;</p>
            <strong>8. COPYRIGHT NOTICE</strong>
            <p>
              All content (including Relevant Richemont Entity Materials) on B2B Extranet are either
              Copyright &copy; Relevant Richemont Entity or are licensed for use by Relevant
              Richemont Entity. All rights are reserved.
            </p>
            <p>&nbsp;</p>
            <strong>9. CONFIDENTIALITY </strong>
            <ol>
              <li>
                Authorized Partner represents and guarantees that it will not disclose to any third
                party or use for its own purposes or purposes of others, other than as set out
                herein, either during or following termination of its access to B2B Extranet, any
                information or documents relating to B2B Extranet. It is understood, that this
                obligation applies to any technical, business, product, strategic, client,
                advertising, financial, legal or other information of any nature whatsoever.
              </li>
              <li>
                No material, document, or any part thereof related to B2B Extranet may be
                reproduced, duplicated, republished, posted, copied, transmitted, re-engineered by
                any means of whatsoever nature, or used, shared or distributed to third parties in
                any way whatsoever other than as stipulated herein.
              </li>
              <li>
                Articles 10.a and 10.b shall not apply to information that is: (i) available to the
                public other than by a breach of these Terms of Use; (ii) rightfully received from a
                third party not in breach of an obligation of confidentiality; (iii) independently
                developed by the Authorized Partner without access to confidential information as
                evidenced by the Authorized Partner's written records; (iv) known to the Authorized
                Partner prior to the time of disclosure as evidenced by Authorized Partner's
                pre-disclosure written records; or (v) produced in compliance with applicable law or
                a court order, provided Relevant Richemont Entity is given reasonable notice of such
                law order and an opportunity to attempt to preclude or limit such production.
              </li>
              <li>
                All computer readable files, including all accessory documents such as, but not
                limited to circular letters, specific instructions accessible from B2B Extranet or
                forwarded to Authorized Partner and/or its employees in whatever form are and remain
                the property of Relevant Richemont Entity and/or its affiliated companies at all
                times and, as such, are protected by international and national copyright
                legislation.
              </li>
              <li>
                In the event that Authorized Partner&rsquo;s right to access and use B2B Extranet is
                terminated, any documentation, data reflecting any confidential information,
                including any documents which the Authorized Partner obtained through B2B Extranet,
                in any form whatsoever, shall be promptly destroyed and any computer readable copies
                deleted from the Authorized Partner's computer system. Authorized Partner shall
                provide written confirmation that the deletion of such information has been effected
                upon request from Relevant Richemont Entity.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>10. LIABILITY</strong>
            <ol>
              <li>
                This section applies only to B2B Extranet and Authorized Partner&rsquo;s use thereof
                and not to any products that may be sold to Authorized Partner (which shall be
                governed by separate terms and conditions).
              </li>
              <li>
                Authorized Partner shall be liable for any act or omission in violation of the
                present Terms of Use performed by its officers, directors, employees, and/or any
                other third party to whom Authorized Partner granted access in violation of the
                present Terms of Use. Authorized Partner agrees to fully indemnify, defend and hold
                Relevant Richemont Entity, its affiliated companies, agents, successors and assigns
                harmless from and against any loss, cost, damage or expense arising by reason of a
                breach by Authorized Partner, or any non-compliance with these Terms of Use by its
                officers, directors, employees and/or any other third party as aforementioned. The
                terms of this section shall survive termination of the Authorized Partner's access
                to B2B Extranet.
              </li>
              <li>
                Relevant Richemont Entity tries to ensure that the information provided is accurate
                and complete. However, Relevant Richemont Entity does not warrant or represent that
                Relevant Richemont Entity&rsquo;s Material is accurate, error-free or reliable or
                that use of Relevant Richemont Entity Material will not infringe rights of third
                parties.
              </li>
              <li>
                Relevant Richemont Entity does not warrant that the functional and/or technical
                aspects of B2B Extranet or the Relevant Richemont Entity Material will be error free
                or that B2B Extranet, Relevant Richemont Entity Material or the servers that make
                them available are free of viruses or other harmful components. If use of B2B
                Extranet or Relevant Richemont Entity Material results in the need for servicing or
                replacing property, material, equipment, data or other element, Relevant Richemont
                Entity is not responsible for those costs. Without limiting the foregoing,
                everything on B2B Extranet is provided "AS IS" AND "AS AVAILABLE" AND, TO THE
                FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WITHOUT WARRANTY OF ANY KIND, EITHER
                EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, REASONABLE
                CARE AND SKILL, OR NON-INFRINGEMENT. Relevant Richemont Entity and its suppliers
                make no warranties about the Relevant Richemont Entity Material, software, text,
                downloads, graphics, and links, or about results to be obtained from using B2B
                Extranet.
              </li>
              <li>
                To the fullest extent permitted by applicable law, Relevant Richemont Entity shall
                not be liable for any indirect, incidental, special or consequential damages of any
                kind arising out of or in connection with the use of information available from B2B
                Extranet or any liability relating to any loss of use, interruption of business,
                lost profits or lost data, regardless of the form of action, whether in contract,
                tort (including negligence) or otherwise, even if Relevant Richemont Entity has been
                advised of the possibility of such damages.
              </li>
              <li>
                Authorized Partner shall be liable for any act or omission in violation of the
                present Terms of Use performed by its officers, directors, employees (whether past
                or present) and/or any other third party to whom Authorized Partner granted access
                in violation of the present Terms of Use. The terms of this section shall survive
                termination of the Authorized Partner's access to B2B Extranet.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>11. LINKS & LINKING</strong>
            <ol>
              <li>
                B2B Extranet may contain links to other platforms operated by third parties not
                affiliated to Relevant Richemont Entity. The inclusion of any link to such third
                party sites does not imply endorsement by Relevant Richemont Entity of those sites.
                Relevant Richemont Entity has not reviewed all of the content contained in the
                linked sites and is not responsible for the content or accuracy of any off-site
                pages or any other sites linked to any of B2B Extranet.
              </li>
              <li>
                If Authorized Partner chooses to click through any link to off-site pages or third
                party sites then this is at Authorized Partner&rsquo;s own risk. Relevant Richemont
                Entity does not authorise linking to B2B Extranet from a third party platform
                without its express prior written authorisation.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>12. GENERAL PROVISIONS</strong>
            <ol>
              <li>
                Unless otherwise specified, the information and materials presented on B2B Extranet
                are presented solely for the purpose of promoting Relevant Richemont Entity&rsquo;s
                products and services and in certain cases to present products for sale via a
                variety of means. Relevant Richemont Entity makes no representation that Relevant
                Richemont Entity Material is appropriate or available for use in every country of
                the world. Authorized Partner is responsible for compliance with applicable local
                laws, keeping in mind that access to Relevant Richemont Entity Material may not be
                legal by certain persons or in certain countries. Our products are available in many
                parts of the world. However, B2B Extranet may identify products that are not
                available worldwide.
              </li>
              <li>
                If any provision, or part of a provision, of these Terms of Use is found to be
                illegal, invalid or unenforceable, that provision or part-provision shall be deemed
                not to form part of these Terms of Use, and the legality, validity or enforceability
                of the remainder of the provisions of these Terms of Use shall not be affected,
                unless otherwise required by operation of applicable law.
              </li>
              <li>
                These Terms of Use constitute the entire agreement between Authorized Partner and us
                in relation to the use of B2B Extranet, and replace and extinguish all prior
                agreements, draft agreements, arrangements, undertakings, or collateral contracts of
                any nature made by the parties, whether oral or written, in relation to such subject
                matter.
              </li>
              <li>
                The waiver by Relevant Richemont Entity of a breach of any provision of these Terms
                of Use will not operate to be interpreted as a waiver of any other or subsequent
                breach.
              </li>
              <li>
                No fees will be invoiced to Authorized Partner for the use of and access to B2B
                Extranet, unless separately agreed upon in writing. Authorized Partner agree that
                any costs, related to the access to and use of B2B Extranet by Authorized Partner
                and its employees including, but not limited to hardware, software and any internet
                connection charges shall be entirely borne by the Authorized Partner.
              </li>
              <li>
                In case of a discrepancy between (1) the Authorized Dealer Agreement or Boutique
                Agreement (as applicable), (2) the general terms and conditions of sale of Relevant
                Richemont Entity, (3) these Terms of Use and/or (4) the content of the B2B Extranet
                intranet, the Authorized Partner agrees and accepts that the higher ranked document
                in the above order of prevalence shall prevail.
              </li>
              <li>
                The provisions of sections 5, 6, 10, 11, 13 and 14 shall survive termination of
                Authorized Partner&rsquo;s access to B2B Extranet.
              </li>
            </ol>
            <p>&nbsp;</p>
            <strong>13. APPLICABLE LAW AND JURISDICTION </strong>
            <ol>
              <li>
                These Terms of Use shall be governed by and construed in accordance with the laws of
                Switzerland, without reference to conflict of laws provisions. Any dispute,
                controversy or claim arising out of or in relation to the Terms of Use, including
                the validity, invalidity, breach or termination thereof, shall be adjudicated or
                arbitrated in accordance with said Terms of Use.
              </li>
              <li>
                In the event that any dispute arises out of or in relation to the interpretation,
                validity and/or application of these Terms of Use, the parties hereto agree to meet
                in good faith in order to resolve the dispute amicably. Failing amicable resolution
                within 30 days from the notification of the dispute, the dispute shall be resolved
                by arbitration in accordance with the Swiss Rules of International Arbitration of
                the Swiss Chambers of Commerce (&ldquo;<strong>the Rules</strong>
                &rdquo;) in force on the date when the notice of arbitration is submitted in
                accordance with the Rules. The arbitration will take place in Geneva, Switzerland
                and the language of the procedure shall be English. The arbitral tribunal will be
                composed of one (1) arbitrator who will be designated in accordance with the Rules.
                The arbitration award shall be final and binding upon the parties, the parties
                renouncing to appeal against the arbitration award by any ordinary or extraordinary
                means, whatever the subject of the arbitration is.
              </li>
            </ol>
          </DialogContent>
          <DialogActions
            noCancel={!hasAccepted}
            cancelTerm={f({ id: 'close' })}
            noCta={hasAccepted}
            onClose={() => props.setIsOpen(false)}
            onClick={() => {
              dispatch(selfService.actions.updateLegalRequest())
            }}
          >
            {f({ id: 'accept' })}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default TermsDialog
