import { all, call, put, take } from 'redux-saga/effects'
import { actions } from '.'
import * as selfService from '../self'

function* workInit() {
  yield put(selfService.actions.infoRequest())
}

function* watchInit() {
  yield take(actions.init)
  yield call(workInit)
}

export function* main() {
  yield all([watchInit()])
}
