import { createSelector } from 'reselect'
import { typedSelector } from '../../types'

export const getSelf = typedSelector(store => store.self)
export const getSelfInfo = createSelector(getSelf, self => self.main.info)
export const getSelfIsExternal = createSelector(getSelf, self => self.main.info?.external)
export const getSelfIsAdmin = createSelector(
  getSelf,
  self => !!self.main.info?.role?.features?.includes('users') ?? false
)
export const getSelfHasBundler = createSelector(
  getSelf,
  self => !!self.main.info?.role?.features?.includes('bundler') ?? false
)
