import React, { lazy } from 'react'
import { useSelector } from 'react-redux'
import * as selfService from '../services/self'
import Router, { IRoute } from './Router'

export default () => {
  const selfIsAdmin = useSelector(selfService.selectors.getSelfIsAdmin)
  const selfHasBundler = useSelector(selfService.selectors.getSelfHasBundler)

  const routes: IRoute[] = [
    {
      name: 'Home',
      path: '/',
      component: lazy(() => import('../views/HomePage')),
    },
    {
      name: 'Catalog',
      path: ['/catalog', '/catalog/:reference'],
      params: 'reference',
      component: lazy(() => import('../views/CatalogPage')),
    },
    {
      name: 'Cart',
      path: ['/cart', '/cart/:reference'],
      params: 'reference',
      component: lazy(() => import('../views/CartPage')),
      options: {
        condition: !!selfHasBundler,
      },
    },
    {
      name: 'Users',
      path: '/users',
      component: lazy(() => import('../views/UserIndexPage')),
      options: {
        condition: !!selfIsAdmin,
        noScroll: true,
      },
    },
  ]

  return <Router routes={routes} />
}
