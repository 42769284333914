import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '.'
import { State, Action } from './types'

const initialState: State = {
  index: {
    isBusy: false,
    query: {},
    facets: undefined,
    results: undefined,
  },
  single: undefined,
}

export default createReducer<State, Action>(initialState)
  .handleAction(actions.queryUpdate, (state, action) =>
    produce(state, draft => {
      draft.index.isBusy = true
      draft.index.query = action.payload
    })
  )
  .handleAction(actions.searchResponse, (state, action) =>
    produce(state, draft => {
      draft.index.isBusy = false
      draft.index.facets = action.payload.data.facets
      draft.index.results = action.payload.data.results
    })
  )
  .handleAction(actions.singleRequest, state =>
    produce(state, draft => {
      draft.single = undefined
    })
  )
  .handleAction(actions.singleResponse, (state, action) =>
    produce(state, draft => {
      draft.single = action.payload.data
    })
  )
  .handleAction(actions.singleClear, state =>
    produce(state, draft => {
      draft.single = undefined
    })
  )
