import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  '@keyframes fade-in': {
    from: { opacity: 0, transform: 'translateY(0)' },
    to: { opacity: 1, transform: 'translateY(-90px)' }
  },
  page: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
    opacity: 0,
    animationName: '$fade-in',
    animationFillMode: 'forwards',
    animationDuration: `${theme.transitions.duration.standard}ms`,
    animationTimingFunction: theme.transitions.easing.easeInOut,
    animationDelay: '100ms'
  }
}))
