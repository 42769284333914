import { put, takeLatest } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import * as apiService from '../../api'
import { takeAction } from '../../../types'

export function* watchAllowed() {
  yield takeLatest(actions.allowedReq, function* (action) {
    yield put(
      apiService.actions.get({
        endpoint: API_CONSTANT_MAP.assortment.indexAllowed,
        responseAction: actions.allowedRes,
      })
    )

    const resAction = yield* takeAction(actions.allowedRes)

    action.payload.callback(resAction.payload)
  })
}
