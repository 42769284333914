import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  progress: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(2)
  }
}))
