import { createStyles, Theme } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1)
    },
    menuItem: {
      padding: theme.spacing(1, 5)
    },
    secondary: {
      color: theme.palette.secondary.main
    },
    children: {
      ...theme.typography.caption,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 'normal',
      whiteSpace: 'nowrap',
      transform: 'translateY(7px)',
      height: 28,
      width: 28
    }
  })
