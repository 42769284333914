import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { detect } from 'detect-browser'
import Notify from 'notifyjs'
import React, { FC, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import CookieConsent from './components/CookieConsent'
import { messages } from './i18n/en'
import Router from './router'
import * as applicationService from './services/application'
import { store } from './services/store'
import { theme } from './styles/theme'
import DeprecatedBrowserBar from './views/DeprecatedBrowserBar/DeprecatedBrowserBar'

const App: FC = _ => {
  useEffect(() => {
    try {
      Notify.needsPermission && Notify.requestPermission()
    } catch (error) {}

    store.dispatch(applicationService.actions.init())
  }, [])

  const browser = detect()

  return (
    <MuiThemeProvider theme={theme}>
      <IntlProvider locale={process.env.REACT_APP_LOCALE} messages={messages}>
        <Provider store={store}>
          <CssBaseline />
          <Router />
          <CookieConsent />
          {browser?.name === 'ie' && <DeprecatedBrowserBar />}
        </Provider>
      </IntlProvider>
    </MuiThemeProvider>
  )
}

export default App
