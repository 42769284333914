import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { User } from '../../user/types'

export const allowedAssortmentsRequest = createAction('👤 SELF / ALLOWED_ASSORTMENTS => REQUEST')()
export const allowedAssortmentsResponse = createAction(
  '👤 SELF / ALLOWED_ASSORTMENTS => RESPONSE'
)<AxiosResponse>()

export const allowedRolesRequest = createAction('👤 SELF / ALLOWED_ROLES => REQUEST')()
export const allowedRolesResponse = createAction(
  '👤 SELF / ALLOWED_ROLES => RESPONSE'
)<AxiosResponse>()

export const allowedLocationsRequest = createAction('👤 SELF / ALLOWED_LOCATIONs => REQUEST')()
export const allowedLocationsResponse = createAction(
  '👤 SELF / ALLOWED_LOCATIONS => RESPONSE'
)<AxiosResponse>()

export const resetAllowed = createAction('👤 SELF / ALLOWED => RESET')()

export const authorize = createAction('👤 SELF / AUTHENTICATE => AUTHORIZE')<User>()
export const unauthorize = createAction('👤 SELF / AUTHENTICATE => UNAUTHORIZE')()

export const authenticateRequest = createAction('👤 SELF / AUTHENTICATE => REQUEST')<{
  body: {
    captcha: string
    email: string
    password: string
    persist: boolean
  }
  callback: (response: AxiosResponse) => void
}>()
export const authenticateResponse = createAction(
  '👤 SELF / AUTHENTICATE => RESPONSE'
)<AxiosResponse>()

export const infoRequest = createAction('👤 SELF / INFO => REQUEST')()
export const infoResponse = createAction('👤 SELF / INFO => RESPONSE')<AxiosResponse>()

export const logoutRequest = createAction('👤 SELF / LOGOUT => REQUEST')()
export const logoutResponse = createAction('👤 SELF / LOGOUT => RESPONSE')<AxiosResponse>()

export const updateLegalRequest = createAction('👤 SELF / LEGAL_UPDATE => REQUEST')()
export const updateLegalResponse = createAction(
  '👤 SELF / LEGAL_UPDATE => RESPONSE'
)<AxiosResponse>()
export const acceptLegal = createAction('👤 SELF / LEGAL => ACCEPT')()
