import { put, takeLeading } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import { takeAction } from '../../../types'
import * as apiService from '../../api'

export function* watchNew() {
  yield takeLeading(actions.newReq, function* (action) {
    yield put(
      apiService.actions.post({
        endpoint: API_CONSTANT_MAP.user.v2.create,
        body: action.payload.body,
        responseAction: actions.newRes,
      })
    )

    const resAction = yield* takeAction(actions.newRes)

    action.payload.callback(resAction.payload)
  })
}
