import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '..'
import { Action } from '../types'

interface State {
  status: 'UP' | 'DOWN'
  error?: any
}

const initialState: State = {
  status: 'UP',
  error: undefined,
}

export default createReducer<State, Action>(initialState).handleAction(
  actions.statusIsDown,
  (state, action) =>
    produce(state, draft => {
      draft.status = 'DOWN'
      draft.error = action.payload
    })
)
