import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#3378af',
      main: '#01579b',
      dark: '#003c6c'
    },
    secondary: {
      light: '#db5858',
      main: '#d32f2f',
      dark: '#932020'
    },
    error: {
      light: '#db5858',
      main: '#d32f2f',
      dark: '#932020'
    }
  }
})
