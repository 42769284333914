import { AxiosResponse } from 'axios'
import { createAction } from 'typesafe-actions'
import { IBaseQuery } from '../../types'
import { IArticle } from './types'

export const updateLength = createAction('🛒 CART / LENGTH => UPDATE')<number>()

export const indexRequest = createAction('🛒 CART / INDEX => REQUEST')()
export const indexResponse = createAction('🛒 CART / INDEX => RESPONSE')<AxiosResponse>()
export const resetIndexResults = createAction('🛒 CART / INDEX_RESULTS => RESET')()

export const loadMoreRequest = createAction('🛒 CART / LOAD_MORE => REQUEST')<string>()
export const loadMoreResponse = createAction('🛒 CART / LOAD_MORE => RESPONSE')<
  AxiosResponse
>()

export const singleRequest = createAction('🛒 CART / SINGLE => REQUEST')<string>()
export const singleResponse = createAction('🛒 CART / SINGLE => RESPONSE')<
  AxiosResponse<IArticle[]>
>()
export const singleReset = createAction('🛒 CART / SINGLE => RESET')()

export const addRequest = createAction('🛒 CART / ADD => REQUEST')<
  Omit<IArticle, 'selected'>[]
>()
export const addResponse = createAction('🛒 CART / ADD => RESPONSE')<AxiosResponse>()
export const removeRequest = createAction('🛒 CART / REMOVE => REQUEST')<
  Omit<IArticle, 'selected'>[]
>()
export const removeResponse = createAction('🛒 CART / REMOVE => RESPONSE')<AxiosResponse>()
export const unqueueArticleToProcess = createAction(
  '🛒 CART / ARTICLE_TO_PROCESS => UNQUEUE'
)()

export const addAllRequest = createAction('🛒 CART / ADD_ALL => REQUEST')<{
  query: IBaseQuery
  count: number
}>()
export const addAllResponse = createAction('🛒 CART / ADD_ALL => RESPONSE')<AxiosResponse>()
export const removeAllRequest = createAction('🛒 CART / REMOVE_ALL => REQUEST')()
export const removeAllResponse = createAction('🛒 CART / REMOVE_ALL => RESPONSE')<
  AxiosResponse
>()
export const referencesToAdd = createAction('🛒 CART / REFERENCES => TO_ADD')<string[]>()
export const referencesToRemove = createAction('🛒 CART / REFERENCES => TO_REMOVE')<
  string[]
>()
export const referencesIsProcessed = createAction('🛒 CART / REFERENCES => IS_PROCESSED')<
  string[]
>()

export const copyRequest = createAction('🛒 CART / COPY => REQUEST')()
export const copyResponse = createAction('🛒 CART / COPY => RESPONSE')<AxiosResponse>()
