import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import React, { FC } from 'react'
import ReactCookieConsent from 'react-cookie-consent'
import { useIntl } from 'react-intl'
import { useStyles } from './styles'

const CookieConsent: FC = _ => {
  const { formatMessage: f } = useIntl()

  const classes = useStyles()

  return (
    <ReactCookieConsent
      ButtonComponent={(props: any) => (
        <IconButton
          className={classes.close}
          aria-label={f({ id: 'close' })}
          onClick={props.onClick}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
      buttonText="Agree"
      containerClasses={classes.container}
      contentClasses={classes.content}
      disableStyles
    >
      <Typography variant="body2">
        We use cookie to improve your experience on our site. By using our site you consent cookies
      </Typography>
    </ReactCookieConsent>
  )
}

export default CookieConsent
