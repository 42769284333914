import { put, takeLatest } from 'redux-saga/effects'
import { actions } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import * as apiService from '../../api'

export function* watchAllowed() {
  yield takeLatest(actions.allowedReq, function* () {
    yield put(
      apiService.actions.get({
        endpoint: API_CONSTANT_MAP.role.indexAllowed,
        responseAction: actions.allowedRes,
      })
    )
  })
}
