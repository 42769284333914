export const messages = {
  accept: 'Accept',
  add_all: 'Add all',
  add_all_to_cart: 'Add all to cart',
  add_all_to_selection: 'Add all to selection',
  add_any_user_assortments_long:
    'You can add one or more assortments to {count, plural, one {the selected user} other {all selected users (#)}}',
  add_assortments: 'Add assortments',
  add_to_cart: 'Add to cart',
  add_to_selection: 'Add to selection',
  add_user: 'Add user',
  adding_any_product: 'Adding product(s)',
  admin: 'Admin',
  administration: 'Administration',
  already_in_user_list: 'User is already in your list',
  and_or_define_your_own_dimensions_in_px: 'And/or define your own dimensions in pixel',
  any_available_product: 'Available product(s)',
  any_product: '{count, plural, one {# product} other {# products}}',
  any_product_in_assortment: 'Product(s) in assortment',
  any_reference_copied_to_clipboard:
    '{count, plural, one {# reference} other {# references}} copied to clipboard',
  any_references_copied_to_clipboard:
    '{count, plural, one {# reference} other {# references}} copied to clipboard',
  are_you_sure_delete_email_or_many_users:
    'Are you sure you want to delete {count, plural, one {{strong}} other {{strong} users}}?',
  are_you_sure_delete_name: 'Are you sure you want to delete {name}',
  are_you_sure_remove_email_or_many_users:
    'Are you sure you want to remove {count, plural, one {{strong}} other {{strong} users}}?',
  assortment: 'Assortment',
  assortment_created_long: 'Assortment was successfully created',
  assortment_creation_top_edito:
    'Complete the form to create an assortment. A market is an assortment that can have exclusive products',
  assortment_deleted_long: 'Assortment was successfully deleted',
  assortment_edition_top_edito:
    'Complete the form to create an assortment. A market is an assortment that can have exclusive products',
  assortment_to_manage: 'Assortment to manage',
  assortment_udpated_long: 'Assortment was successfully updated',
  assortments: 'Assortments',
  assortments_partially_assigned: 'Certain assortments could not be assigned',
  assortments_partially_removed: 'Certain assortments could not be removed',
  assortments_successfully_assigned:
    'Assortments have been successfully assigned to {count, plural, one {user} other {users}}',
  assortments_successfully_removed:
    'Assortments have been successfully removed from {count, plural, one {user} other {users}}',
  available: 'Available',
  back_to_catalog: 'Back to catalog',
  bad_request: 'Bad request',
  bracelet: 'Bracelet',
  brand: 'Brand',
  buckles: 'Buckles',
  business_reference: 'Business reference',
  cancel: 'Cancel',
  cart: 'Cart',
  cart_is_empty: 'Cart is empty',
  case: 'Case',
  catalog: 'Catalog',
  central_admin: 'Central admin',
  checkout_languages_edito:
    'Product information is the textual content related to the product. This will also include asset URLs (both images and videos). Choose the language to download (multiple selection)',
  checkout_package_name_edito: 'Choose a file name for the package',
  checkout_resolutions_edito:
    'This will not include videos. Choose the resolution to download (multiple selection)',
  clasp_system: 'Clasp system',
  clear: 'Clear',
  clear_all: 'Clear all',
  clear_cart: 'Clear cart',
  clear_selection: 'Clear selection',
  close: 'Close',
  collapse: 'Collapse',
  collecting: 'Collecting',
  collection: 'Collection',
  collection_description: 'Collection description',
  color: 'Color',
  confirm: 'Confirm',
  confirm_password: 'Confirm password',
  contact_email: 'Contact email',
  contact_or_support: 'Contact / Support',
  copy_references_to_clipboard: 'Copy references to clipboard',
  copy_to_clipboard: 'Copy to clipboard',
  copying: 'Copying',
  cosc: 'COSC',
  countries: 'Countries',
  country: 'Country',
  create_assortment: 'Create assortment',
  create_children: 'Create children',
  create_package: 'Create package',
  create_password: 'Create password',
  create_user: 'Create user',
  creating_package_ellipsis: 'Creating package...',
  culture: 'Culture',
  decor: 'Decor',
  define_assortment_as_market_question_mark: 'Define assortment as market ?',
  delete: 'Delete',
  delete_any_user: 'Delete {count, plural, one {user} other {users}}',
  delete_assortment: 'Delete assortment',
  delete_user: 'Delete user',
  description: 'Description',
  details: 'Details',
  dial: 'Dial',
  diameter: 'Diameter',
  diameter_lines: 'Diameter lines',
  digital_assets: 'Articles Images',
  directly_assign_new_references_question_mark:
    'Directly assign new references to my child assortments?',
  distance_between_horns: 'Distance between horns',
  domain: 'Domain',
  download: 'Download',
  downloads: 'Downloads',
  edit: 'Edit',
  edit_assortment: 'Edit assortment',
  edit_user: 'Edit user',
  email: 'Email',
  email_is_internal_user: 'Email is internal user',
  email_not_found: 'Email not found',
  energy: 'Energy',
  engraving: 'Engraving',
  enter_email: 'Enter email',
  enter_package_name: 'Enter package name',
  enter_your_information_below: 'Enter your information below',
  extended_search_top_edito: 'Enter a list of references',
  filters: 'Filters',
  finishing: 'Finishing',
  first_name: 'First name',
  for_any_questions_long:
    'For any questions related to product catalog or brand scope please contact your local Maison representative',
  forgot_password: 'Forgot password?',
  formats: 'Formats',
  frequence_hz: 'Frequency Hz',
  frequence_vph: 'Frequency Vph',
  full_name: 'Full name',
  functions: 'Functions',
  geneva_seal: 'Geneva seal',
  go_to_top: 'Go to top',
  hands_shape: 'Hands shape',
  hands: 'Hands',
  height: 'Height',
  hide: 'Hide',
  hide_technical_details: 'Hide technical details',
  high: 'High',
  high_1080p: 'High (1080p)',
  home: 'Home',
  index_color: 'Index color',
  interchangeable: 'Interchangeable',
  internal: 'Internal',
  languages: 'Languages',
  last_name: 'Last name',
  launch_date: 'Launch date',
  lc_ar: 'Arabic',
  lc_de: 'German',
  lc_en: 'English',
  lc_en_UK: 'English - United Kingdom',
  lc_es: 'Spanish',
  lc_fr: 'French',
  lc_it: 'Italian',
  lc_ja: 'Japanese',
  lc_ko: 'Korean',
  lc_pt: 'Portuguese',
  lc_pt_BR: 'Portuguese - Brasil',
  lc_ru: 'Russian',
  lc_zh_CN: 'Chinese',
  lc_zh_HK: 'Chinese - Hong Kong',
  lc_zh_TW: 'Chinese - Taiwan',
  length: 'Length',
  local_admin: 'Local admin',
  location: 'Location',
  locations: 'Locations',
  log_out: 'Log out',
  low: 'Low',
  low_480p: 'Low (480p)',
  main_material: 'Main material',
  maisons: 'Maisons',
  manage_assortments: 'Manage assortments',
  manage_users: 'Manage users',
  managed_assortment: 'Managed assortment',
  management_mode: 'Management mode',
  manager_user: 'Manager user',
  market: 'Market',
  material: 'Material',
  maybe_password_recovery_long:
    'If the email address provided is valid, you will receive an e-mail with instructions on how to reset your password',
  medias: 'Medias',
  medium: 'Medium',
  medium_720p: 'Medium (720p)',
  movement: 'Movement',
  must_be_email: 'Must be email',
  must_be_url: 'Must be url',
  name: 'Name',
  new: 'New',
  next_product: 'Next product',
  no_package_yet: 'No package yet',
  not_defined: 'Not defined',
  number_of_barrels: 'Number of barrels',
  number_of_rubis: 'Number of rubies',
  number_of_rubies: 'Number of rubies',
  number_of_pieces: 'Number of pieces',
  numerals_color: 'Numerals color',
  package_created_long: 'Package was successfully created',
  package_error_long: 'An error occured while creating package',
  package_name: 'Package name',
  password: 'Password',
  password_create_edito: 'To create your password, please complete the following fields',
  password_must_be_between_min_and_max_characters:
    'Your password MUST be between {min} and {max} characters in length',
  password_must_be_changed: '',
  password_must_have_one_number: 'Your password MUST have at least one number',
  password_must_have_one_special:
    'Your password MUST have at least one Special (Non-Alphanumeric) character (eg. ! @ # $ % ^ & * )',
  password_must_have_one_uppercase: 'Your password MUST have at least one UPPERCASE character',
  password_must_match: 'Passwords must match',
  password_must_not_contain_spaces: 'Your password MUST NOT contain spaces.',
  password_recovery: 'Password recovery',
  password_recovery_edito:
    'Enter your email address and we will send you a link to reset your password',
  password_reset_edito: 'To reset your password, please complete the following fields',
  password_successfully_changed: 'Password successfully changed',
  password_successfully_created: 'Password successfully created',
  password_too_short_long: 'Password is too short - should be 8 chars minimum',
  past_references_here_ellipsis: 'Past references here...',
  power_reserve: 'Power reserve',
  prev_product: 'Previous product',
  privacy_policy: 'Privacy Policy',
  product_family: 'Product family',
  product_information: 'Product information',
  product_type: 'Product type',
  profile: 'Profile',
  range_status: 'Range status',
  reference: 'Reference',
  reference_not_found: 'Reference not found',
  references: 'References',
  remember_me: 'Remember me',
  remove: 'Remove',
  remove_any_user: 'Remove {count, plural, one {user} other {users}}',
  remove_any_user_assortments_long:
    'You can remove one or more assortments to {count, plural, one {the selected user} other {all selected users (#)}}',
  remove_assortments: 'Remove assortments',
  remove_from_cart: 'Remove from cart',
  remove_from_selection: 'Remove from selection',
  required: 'Required',
  reset_password: 'Reset password',
  resolutions: 'Resolutions',
  role: 'Role',
  roles: 'Roles',
  sale_user: 'Sale user',
  sales_user: 'Sales user',
  search: 'Search',
  search_ellipsis: 'Search...',
  search_in_catalog_ellipsis: 'Search in catalog...',
  search_with_references: 'Search with references',
  select_all: 'Select all',
  select_at_least_one: 'Select at least one',
  select_the_relevant_brand: 'Select the relevant Brand',
  select_to_show_more_actions_ellipsis: 'Select to show more actions...',
  selected: 'Selected',
  selection: 'Selection',
  selection_is_empty: 'Selection is empty',
  send_mail: 'Send mail',
  serial: 'Serial',
  shape: 'Shape',
  share: 'Share',
  short_description: 'Short description',
  show: 'Show',
  show_less_details: 'Show less details',
  show_more_details: 'Show more details',
  show_more_ellipsis: 'Show more...',
  show_technical_details: 'Show technical details',
  sign_in: 'Sign in',
  sign_in_with_your_richemont_account: 'Sign in with your Richemont account',
  size: 'Size',
  size_range: 'Size range',
  specific_highlights: 'Specific highlights',
  state: 'State',
  stones: 'Stones',
  sub_collection: 'Subcollection',
  sub_collection_text: 'Subcollection description',
  successfully_added_any_product: 'Successfully added product(s)',
  swap: 'Swap',
  switch_to_back_office: 'Switch to back office',
  switch_to_front_office: 'Switch to front office',
  target: 'Target',
  terms_and_conditions: 'Terms & Conditions',
  the_following_references_could_not_be_found: 'The following references could not be found',
  thickness: 'Thickness',
  token_not_valid: 'Token is not valid',
  transformable: 'Transformable',
  transformable_description: 'Transformable description',
  type: 'Type',
  unknown_extranet_user: 'Please ask admin to have access to the extranet',
  unkown_extranet_user: '',
  update_assortment: 'Update assortment',
  update_user: 'Update user',
  user_creation_email_edito:
    "In order to complete the form add new user's email in the field below",
  user_creation_role_edito:
    'User with Manager role can view catalog and download product assets while user with Sales role can only view catalog',
  user_edition_admin_role_edito:
    'User with Admin role is a Richemont internal user that can view the Catalog and access to administration. For this type of users, you need to assign the assortment to manage',
  user_edition_internal_role_edito:
    'User with Internal role is a Richemont internal user that can view the Catalog only – No access to administration',
  user_is_an_internal: 'User is internal',
  user_successfully_created: 'User was successfully created',
  user_successfully_deleted: 'User was successfully deleted',
  user_successfully_removed: 'User was successfully removed',
  user_successfully_updated: 'User was successfully updated',
  users: 'Users',
  very_high: 'Very high',
  very_high_4k: 'Very high (4K)',
  very_low: 'Very low',
  very_low_360p: 'Very low (360p)',
  waiting: 'Waiting',
  water_resistance: 'Water resistance',
  water_resistance_in_bar: 'Water resistance (bar)',
  water_resistance_in_m: 'Water resistance (m)',
  website: 'Website',
  weight: 'Weight',
  width: 'Width',
  worldwide_price: 'Worldwide Price',
  wrong_credentials_long: 'You have entered an incorrect email or password',
  your_search_contains_illegal_characters: 'Your search contains illegal characters',
  zipping: 'Zipping',
}
