import { createIntl } from 'react-intl'
import {
  all,
  call,
  delay,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { actions } from '.'
import { API_CONSTANT_MAP, BUNDLE_STATUS_CONSTANT } from '../../helpers/constants'
import { messages } from '../../i18n/en'
import * as apiService from '../api'
import * as snackService from '../snack'
import { IBundle } from './types'
import Notify from 'notifyjs'

const intl = createIntl({
  locale: process.env.REACT_APP_LOCALE,
  messages,
})

const f = intl.formatMessage

function* workLanguages() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.languages.index,
      responseAction: actions.languagesResponse,
    })
  )
}

function* workResolutions() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.resolution.index,
      responseAction: actions.resolutionsResponse,
    })
  )
}

function* workFileFormats() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.fileFormat.index,
      responseAction: actions.fileFormatsResponse,
    })
  )
}

function* workIndex() {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.bundle.index,
      responseAction: actions.indexResponse,
    })
  )

  const reponseAction: ReturnType<typeof actions.indexResponse> = yield take(actions.indexResponse)

  yield all(
    reponseAction.payload.data.map(bundle => {
      if (
        bundle.status === BUNDLE_STATUS_CONSTANT.collecting ||
        bundle.status === BUNDLE_STATUS_CONSTANT.copying ||
        bundle.status === BUNDLE_STATUS_CONSTANT.waiting ||
        bundle.status === BUNDLE_STATUS_CONSTANT.zipping
      ) {
        return fork(subscribe, bundle.id)
      }
      return null
    })
  )
}

function* subscribe(id: number) {
  let processing = true
  let bundle: IBundle | null = null

  while (processing) {
    let found = false
    bundle = null

    yield put(actions.singleRequest(id))

    while (!found) {
      const responseAction: ReturnType<typeof actions.singleResponse> = yield take(
        actions.singleResponse
      )

      if (responseAction.payload.data[0].id === id) {
        bundle = responseAction.payload.data[0]
        found = true
      }
    }

    if (bundle) {
      if (
        bundle.status === BUNDLE_STATUS_CONSTANT.ready ||
        bundle.status === BUNDLE_STATUS_CONSTANT.error
      ) {
        processing = false
      } else {
        yield delay(2000)
      }
    }
  }

  if (bundle?.status === BUNDLE_STATUS_CONSTANT.ready) {
    yield call(() => {
      new Notify(document.title, {
        body: f({ id: 'package_created_long' }),
        icon: '/favicon.ico',
        timeout: 6,
      }).show()
    })

    yield put(
      snackService.actions.createSnack({
        content: f({ id: 'package_created_long' }),
        context: 'success',
      })
    )
  }

  if (bundle?.status === BUNDLE_STATUS_CONSTANT.error) {
    yield call(() => {
      new Notify(document.title, {
        body: f({ id: 'package_error_long' }),
        icon: '/favicon.ico',
        timeout: 6,
      }).show()
    })

    yield put(
      snackService.actions.createSnack({
        content: f({ id: 'package_error_long' }),
        context: 'error',
      })
    )
  }
}

function* workSingle(action: ReturnType<typeof actions.singleRequest>) {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.bundle.index,
      params: { id: action.payload },
      responseAction: actions.singleResponse,
    })
  )
}

function* workCreate(action: ReturnType<typeof actions.createRequest>) {
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.bundle.create,
      body: action.payload.body,
      responseAction: actions.createResponse,
    })
  )

  yield take(actions.createResponse)

  yield call(action.payload.callback)

  yield put(actions.indexRequest())
}

function* workDelete(action: ReturnType<typeof actions.deleteRequest>) {
  yield put(
    apiService.actions.del({
      endpoint: API_CONSTANT_MAP.bundle.remove(action.payload),
    })
  )
}

function* watch() {
  yield takeLeading(actions.languagesRequest, workLanguages)
  yield takeLeading(actions.resolutionsRequest, workResolutions)
  yield takeLeading(actions.fileFormatsRequest, workFileFormats)
  yield takeLatest(actions.indexRequest, workIndex)
  yield takeEvery(actions.singleRequest, workSingle)
  yield takeEvery(actions.createRequest, workCreate)
  yield takeEvery(actions.deleteRequest, workDelete)
}

export function* main() {
  yield all([watch()])
}
