import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import DialogContent from '@material-ui/core/DialogContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import Link from '@material-ui/core/Link'
import TablePagination from '@material-ui/core/TablePagination'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/WarningRounded'
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../components/Dialog'
import DialogActions from '../../components/DialogActions'
import { BUNDLE_STATUS_CONSTANT } from '../../helpers/constants'
import * as bundleService from '../../services/bundle'
import { useStyles } from './styles'

const statusToProgress = (status: number) => {
  switch (status) {
    case 2:
      return 10
    case 3:
      return 50
    case 4:
      return 95
    case 5:
    case 10:
      return 100
    case 1:
    default:
      return 0
  }
}

type TProps = Partial<{
  isOpen: boolean
  onClose: () => void
}>

const DownloadDialog: FC<TProps> = props => {
  const dispatch = useDispatch()
  const bundles = useSelector(bundleService.selectors.getBundles)
  const hasNew = useSelector(bundleService.selectors.getHasNew)

  const { formatMessage: f } = useIntl()

  const [page, setPage] = useState(0)
  const rowsPerPage = 5

  const statusToText = (status: number) => {
    switch (status) {
      case 1:
        return f({ id: 'waiting' })
      case 2:
        return f({ id: 'collecting' })
      case 3:
        return f({ id: 'zipping' })
      case 4:
        return f({ id: 'copying' })
      default:
        return ''
    }
  }

  const [isBundling, setIsBundling] = useState(false)

  useEffect(() => {
    if (bundles.length === 0 || bundles[0]?.status === BUNDLE_STATUS_CONSTANT.ready) {
      setIsBundling(true)
    } else {
      !props.isOpen && isBundling && dispatch(bundleService.actions.setHasNew(true))
      setIsBundling(false)
    }
  }, [bundles]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.isOpen && hasNew && dispatch(bundleService.actions.setHasNew(false))
  }, [props.isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()

  return (
    <Dialog title={f({ id: 'downloads' })} isOpen={props.isOpen} onClose={props.onClose}>
      <div>
        <DialogContent>
          {bundles.length > 0 ? (
            bundles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(bundle => (
              <div className={classes.row} key={bundle.id}>
                {(bundle.status === BUNDLE_STATUS_CONSTANT.collecting ||
                  bundle.status === BUNDLE_STATUS_CONSTANT.copying ||
                  bundle.status === BUNDLE_STATUS_CONSTANT.waiting ||
                  bundle.status === BUNDLE_STATUS_CONSTANT.zipping) && (
                  <>
                    <Tooltip title={`${bundle.packageName}.zip`}>
                      <span className={classes.name}>{bundle.packageName}.zip</span>
                    </Tooltip>
                    <Tooltip title={statusToText(bundle.status)}>
                      <div className={classes.progress}>
                        <LinearProgress
                          variant="determinate"
                          value={statusToProgress(bundle.status)}
                        />
                      </div>
                    </Tooltip>
                  </>
                )}
                {bundle.status === BUNDLE_STATUS_CONSTANT.ready && (
                  <>
                    <span className={classes.name} title={bundle.packageName}>
                      {bundle.packageName}.zip
                    </span>
                    <div className={classes.actions}>
                      <Typography className={classes.size} color="textSecondary" variant="caption">
                        {bundle.size}
                      </Typography>
                      <ButtonGroup size="small" variant="outlined">
                        <Button
                          className={classes.downloadButton}
                          color="primary"
                          component={Link}
                          disabled={bundle.s3url === null}
                          href={bundle.s3url ?? ''}
                        >
                          {f({ id: 'download' })}
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            dispatch(bundleService.actions.deleteRequest(bundle.id))
                          }}
                        >
                          {f({ id: 'delete' })}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </>
                )}

                {bundle.status === BUNDLE_STATUS_CONSTANT.error && (
                  <>
                    <div className={classes.nameContainer}>
                      <span
                        className={clsx(classes.name, classes.error)}
                        title={bundle.packageName}
                      >
                        {bundle.packageName}.zip
                      </span>
                      <Tooltip title={f({ id: 'package_error_long' })}>
                        <WarningIcon color="error"></WarningIcon>
                      </Tooltip>
                    </div>
                    <div className={classes.actions}>
                      <Button
                        color="secondary"
                        onClick={() => {
                          dispatch(bundleService.actions.deleteRequest(bundle.id))
                        }}
                        size="small"
                        variant="outlined"
                      >
                        {f({ id: 'delete' })}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <Typography color="textSecondary">{f({ id: 'no_package_yet' })}</Typography>
          )}
          {bundles.length > rowsPerPage && (
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={bundles.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={(_, page) => setPage(page)}
            />
          )}
        </DialogContent>
        <DialogActions onClose={props.onClose} noCta cancelTerm={f({ id: 'close' })} />
      </div>
    </Dialog>
  )
}

export default DownloadDialog
