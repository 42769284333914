import httpCodes from 'http-status-codes'
import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '.'
import { IState, TAction } from './types'

const initialState: IState = {
  articlesToProcess: [],
  isMassProcessing: false,
  isCopying: false,
  index: {
    isBusy: false,
    results: [],
    hasMore: false,
    pageSize: 100
  },
  single: undefined,
  length: 0
}

export default createReducer<IState, TAction>(initialState)
  .handleAction(actions.indexRequest, state =>
    produce(state, draft => {
      draft.index.isBusy = true
      draft.index.hasMore = false
    })
  )
  .handleAction(actions.indexResponse, (state, action) =>
    produce(state, draft => {
      draft.index.results = action.payload.data
      draft.index.isBusy = false
      draft.index.hasMore = action.payload.data.length === draft.index.pageSize
    })
  )
  .handleAction(actions.loadMoreRequest, state =>
    produce(state, draft => {
      draft.index.isBusy = true
    })
  )
  .handleAction(actions.loadMoreResponse, (state, action) =>
    produce(state, draft => {
      draft.index.isBusy = false
      switch (action.payload.status) {
        case httpCodes.OK:
          if (draft.index.results) {
            draft.index.results.push(...action.payload.data)
            draft.index.hasMore = action.payload.data.length === draft.index.pageSize
          }
          break
        case httpCodes.NOT_FOUND:
          draft.index.hasMore = false
          break
        default:
          break
      }
    })
  )
  .handleAction(actions.addRequest, (state, action) =>
    produce(state, draft => {
      for (const article of action.payload) {
        draft.articlesToProcess.push({ article, action: 'add' })
      }
    })
  )
  .handleAction(actions.removeRequest, (state, action) =>
    produce(state, draft => {
      for (const article of action.payload) {
        draft.articlesToProcess.push({ article, action: 'remove' })
        draft.index.results = draft.index.results.filter(result => article.ref !== result.ref)
      }
    })
  )
  .handleAction([actions.addResponse, actions.removeResponse], (state, action) =>
    produce(state, draft => {
      draft.length = action.payload.data.length
    })
  )
  .handleAction(actions.unqueueArticleToProcess, state =>
    produce(state, draft => {
      draft.articlesToProcess.shift()
    })
  )
  .handleAction([actions.addAllRequest, actions.removeAllRequest], state =>
    produce(state, draft => {
      draft.isMassProcessing = true
    })
  )
  .handleAction(actions.addAllResponse, state =>
    produce(state, draft => {
      draft.isMassProcessing = false
    })
  )
  .handleAction(actions.removeAllResponse, state =>
    produce(state, draft => {
      draft.index.results = []
      draft.index.hasMore = false
      draft.isMassProcessing = false
    })
  )
  .handleAction(actions.singleResponse, (state, action) =>
    produce(state, draft => {
      draft.single = action.payload.data
    })
  )
  .handleAction(actions.updateLength, (state, action) =>
    produce(state, draft => {
      draft.length = action.payload
    })
  )
  .handleAction(actions.singleReset, state =>
    produce(state, draft => {
      draft.single = undefined
    })
  )
  .handleAction(actions.resetIndexResults, state =>
    produce(state, draft => {
      draft.index.results = []
    })
  )
  .handleAction(actions.copyRequest, state =>
    produce(state, draft => {
      draft.isCopying = true
    })
  )
  .handleAction(actions.copyResponse, state =>
    produce(state, draft => {
      draft.isCopying = false
    })
  )
