import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useStyles } from './styles'

const PageSection: FC<RouteComponentProps> = props => {
  const classes = useStyles()

  return (
    <div id="main" role="main">
      <div className={classes.page} key={props.location.pathname.split('/')[1]}>
        {props.children}
      </div>
    </div>
  )
}

export default PageSection
