import { useDispatch } from 'react-redux'
import { PayloadActionCreator } from 'typesafe-actions'
import { IBaseFacet, IBaseFilter, IBaseQuery } from '../types'

/**
 * Dispatch an action with different altered queries as payload
 * @param query
 * @param action
 */
export function useDispatchAlteredQuery(query: IBaseQuery, action: PayloadActionCreator<string, IBaseQuery>) {
  const dispatch = useDispatch()

  return {
    withFilterAdded: (facet: IBaseFacet, filter: IBaseFilter) => {
      const newQuery = {
        term: query.term,
        facets: [
          ...(query.facets || []),
          {
            key: facet.key,
            displayName: facet.displayName,
            filters: [filter]
          }
        ]
      }

      dispatch(action(newQuery))
    },
    withFilterRemoved: (facet: IBaseFacet, filter: IBaseFilter) => {
      const newQuery = {
        term: query.term,
        facets: query.facets
          ? query.facets.filter(fct => fct.key !== facet.key || fct.filters[0].id !== filter.id)
          : []
      }

      dispatch(action(newQuery))
    },
    withTermUpdated: (term?: string) => {
      dispatch(action({ term }))
    },
    unfilled: () => {
      dispatch(action({}))
    }
  }
}
