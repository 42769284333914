import MuiDialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react'
import LinearProgress from '../LinearProgress'
import { useStyles } from './styles'

interface Actions {
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
}

type Props = Partial<{
  children: ((actions: Actions) => ReactElement) | ReactElement
  isOpen: boolean
  isLoading: boolean
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose: () => void
  onExited: () => void
  title: string
}>

const Dialog: FC<Props> = props => {
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()

  return (
    <MuiDialog
      fullWidth
      maxWidth={props.maxWidth || 'sm'}
      open={props.isOpen || false}
      onClose={props.onClose}
      onExited={props.onExited}
      scroll="body"
      PaperProps={{
        className: classes.paper,
      }}
      disableBackdropClick={isLoading || props.isLoading}
      disableEscapeKeyDown={isLoading || props.isLoading}
    >
      <DialogTitle className={classes.title}>{props.title}</DialogTitle>
      <LinearProgress
        className={classes.progress}
        active={isLoading || props.isLoading}
      ></LinearProgress>
      {typeof props.children === 'function'
        ? props.children({ isLoading, setIsLoading })
        : props.children}
    </MuiDialog>
  )
}

export default Dialog
