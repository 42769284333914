import httpCodes from 'http-status-codes'
import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '.'
import { IState, TAction } from './types'

const initialState: IState = {
  index: {
    isExtended: true,
    isBusy: false,
    isCopying: false,
    hasMore: true,
    query: {
      language: process.env.REACT_APP_LOCALE,
      pageSize: 100,
    },
    facets: undefined,
    results: undefined,
    notFoundReferences: [],
    total: 0,
  },
  single: undefined,
}

export default createReducer<IState, TAction>(initialState)
  .handleAction(actions.setIndexResultsSelect, (state, action) =>
    produce(state, draft => {
      if (draft.index.results) {
        for (const result of draft.index.results) {
          result.selected = action.payload
        }
      }
    })
  )
  .handleAction(actions.setIndexResultSelect, (state, action) =>
    produce(state, draft => {
      if (draft.index.results) {
        for (const result of draft.index.results) {
          for (const reference of action.payload.references) {
            if (result.ref === reference) {
              result.selected = action.payload.select
            }
          }
        }
      }
    })
  )
  .handleAction(actions.updateIndexQuery, (state, action) =>
    produce(state, draft => {
      draft.index.isBusy = true
      draft.index.query.facets = action.payload.facets
      draft.index.query.term = action.payload.term
      draft.index.query.references = action.payload.references
      draft.index.hasMore = true
      draft.index.total = 0
    })
  )
  .handleAction([actions.searchResponse, actions.searchWithReferencesResponse], (state, action) =>
    produce(state, draft => {
      draft.index.isBusy = false

      if (action.payload.status === httpCodes.OK) {
        draft.index.facets = action.payload.data.facets
        draft.index.results = action.payload.data.results
        draft.index.total = action.payload.data.total
        draft.index.hasMore = draft.index.query.pageSize === action.payload.data.results.length
      }
    })
  )
  .handleAction(actions.setSingle, (state, action) =>
    produce(state, draft => {
      draft.single = action.payload
    })
  )
  .handleAction(actions.resetSingle, state =>
    produce(state, draft => {
      draft.single = undefined
    })
  )
  .handleAction(actions.resetIndexResults, state =>
    produce(state, draft => {
      draft.index.results = []
    })
  )
  .handleAction(actions.loadMoreRequest, state =>
    produce(state, draft => {
      draft.index.isBusy = true
    })
  )
  .handleAction(actions.loadMoreResponse, (state, action) =>
    produce(state, draft => {
      draft.index.isBusy = false
      switch (action.payload.status) {
        case httpCodes.OK:
          if (draft.index.results) {
            draft.index.results.push(...action.payload.data.results)
            draft.index.hasMore = draft.index.query.pageSize === action.payload.data.results.length
          }
          break
        case httpCodes.NOT_FOUND:
          draft.index.hasMore = false
          break
        default:
          break
      }
    })
  )
  .handleAction(actions.setIsExtended, (state, action) =>
    produce(state, draft => {
      draft.index.isExtended = action.payload
    })
  )
  .handleAction(actions.copyRequest, state =>
    produce(state, draft => {
      draft.index.isCopying = true
    })
  )
  .handleAction(actions.copyResponse, state =>
    produce(state, draft => {
      draft.index.isCopying = false
    })
  )
  .handleAction(actions.deltaReferencesResponse, (state, action) =>
    produce(state, draft => {
      draft.index.notFoundReferences = action.payload.data
    })
  )
  .handleAction(actions.clearIndexQueryReferences, state =>
    produce(state, draft => {
      draft.index.query.references = ''
    })
  )
  .handleAction(actions.clearNotFoundReferences, state =>
    produce(state, draft => {
      draft.index.notFoundReferences = []
    })
  )
