import produce from 'immer'
import { createReducer } from 'typesafe-actions'
import { actions } from '..'
import { Action, AssortmentPreview } from '../types'

export interface State {
  index: AssortmentPreview[]
}

const initialState: State = {
  index: [],
}

export default createReducer<State, Action>(initialState).handleAction(
  actions.allowedRes,
  (state, action) =>
    produce(state, draft => {
      draft.index = action.payload.data
    })
)
