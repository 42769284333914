import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import DialogActions from '../../components/DialogActions'

interface IProps {
  onClose: () => void
  references: string[]
}

const NotFoundReferencesDialog: FC<IProps> = props => {
  const references = props.references.join(',')

  const { formatMessage: f } = useIntl()

  return (
    <div>
      <DialogContent>
        <TextField
          defaultValue={references}
          error
          label={f({ id: 'references' })}
          fullWidth
          multiline
          rows={5}
          rowsMax={10}
          variant="outlined"
          inputProps={{
            readOnly: true
          }}
        />
      </DialogContent>
      <DialogActions onClose={props.onClose} cancelTerm={f({ id: 'close' })} noCta>
        {f({ id: 'search' })}
      </DialogActions>
    </div>
  )
}

export default NotFoundReferencesDialog
