import MuiLinearProgress from '@material-ui/core/LinearProgress'
import { withTheme } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { Props, State } from './LinearProgress.types'

class LinearProgress extends Component<Props, State> {
  render() {
    return this.props.active ? (
      <MuiLinearProgress
        className={this.props.className}
        style={{ backgroundColor: this.props.theme.palette.grey[200], minHeight: 1, maxHeight: 1 }}
        value={0}
        variant={this.props.active ? 'query' : 'determinate'}
      />
    ) : (
      <div
        className={this.props.className}
        style={{ backgroundColor: this.props.theme.palette.grey[200], minHeight: 1, ...this.props.style }}
      />
    )
  }
}

export default withTheme(LinearProgress)
