import * as httpCodes from 'http-status-codes'
import { all, call, put, select, take, takeLeading } from 'redux-saga/effects'
import { actions, selectors } from '..'
import { API_CONSTANT_MAP } from '../../../helpers/constants'
import * as apiService from '../../api'
import * as snackService from '../../snack'
import { watchVerify } from './verify.sagas'
import { watchNew } from './new.sagas'

function* workSearch(action: ReturnType<typeof actions.searchRequest>) {
  yield put(actions.queryUpdate(action.payload))
  yield put(
    apiService.actions.post({
      endpoint: API_CONSTANT_MAP.user.search,
      body: action.payload,
      responseAction: actions.searchResponse,
    })
  )
}

function* workSingle(action: ReturnType<typeof actions.singleRequest>) {
  yield put(
    apiService.actions.get({
      endpoint: API_CONSTANT_MAP.user.single(action.payload),
      responseAction: actions.singleResponse,
    })
  )
}

function* workUpdate(action: ReturnType<typeof actions.updateRequest>) {
  yield put(
    apiService.actions.put({
      endpoint: API_CONSTANT_MAP.user.update(action.payload.id),
      body: action.payload.body,
      responseAction: actions.updateResponse,
      expectedStatus: [httpCodes.BAD_REQUEST],
    })
  )

  const responseAction: ReturnType<typeof actions.updateResponse> = yield take(
    actions.updateResponse
  )

  yield call(action.payload.callback, responseAction.payload)
}

function* workDelete(action: ReturnType<typeof actions.deleteRequest>) {
  const index: ReturnType<typeof selectors.getIndex> = yield select(selectors.getIndex)

  yield put(
    apiService.actions.del({
      endpoint: API_CONSTANT_MAP.user.remove(action.payload.id),
      responseAction: actions.deleteResponse,
    })
  )

  const responseAction: ReturnType<typeof actions.deleteResponse> = yield take(
    actions.deleteResponse
  )

  yield put(
    snackService.actions.createSnack({
      content: action.payload.successMessage,
      context: 'success',
    })
  )

  yield put(actions.searchRequest(index.query))

  yield call(action.payload.callback, responseAction.payload)
}

function* workMassAssortments(action: ReturnType<typeof actions.massAssortmentsRequest>) {
  yield put(
    apiService.actions.post({
      body: action.payload.body,
      endpoint:
        action.payload.verb === 'ADD'
          ? API_CONSTANT_MAP.user.addAssortments
          : API_CONSTANT_MAP.user.removeAssortments,
      responseAction: actions.massAssortmentsResponse,
      expectedStatus: [httpCodes.PARTIAL_CONTENT, httpCodes.BAD_REQUEST],
    })
  )

  const responseAction: ReturnType<typeof actions.massAssortmentsResponse> = yield take(
    actions.massAssortmentsResponse
  )

  yield call(action.payload.callback, responseAction.payload)
}

function* watch() {
  yield takeLeading(actions.searchRequest, workSearch)
  yield takeLeading(actions.singleRequest, workSingle)
  yield takeLeading(actions.updateRequest, workUpdate)
  yield takeLeading(actions.deleteRequest, workDelete)
  yield takeLeading(actions.massAssortmentsRequest, workMassAssortments)
}

export function* main() {
  yield all([watch(), watchNew(), watchVerify()])
}
